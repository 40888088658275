<nav class="h-12 bg-light-green">
  <div class="hamburger-menu">
    <div id="nav-icon3" [ngClass]="{ open: menuIsOpen }" (click)="openAndCloseMenu()">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
  <div class="text-2xl text-dark-green relative m-auto font-medium flex items-center gap-2">
    <div>
      <img alt="logo" class="h-8 w-8" src="../../../../assets/dfarm-logo-2.svg" />
    </div>
    <div class="mobileVertical:pr-0 text-off-white">dFarm</div>
  </div>
  <div class="profile-wrapper">
    <img alt="user" [matMenuTriggerFor]="menu" src="../../../../assets/header-icons/user.svg" />
    <!-- <img src="../../../../assets/header-icons/bell.svg" alt="notification" />
    <img src="../../../../assets/header-icons/chat.svg" alt="chat" /> -->
  </div>
</nav>
<mat-menu #menu="matMenu" class="custom-menu">
  <button (click)="openProfileDialog()" mat-menu-item>
    {{ 'navbar.profile-overview' | translate }}
  </button>
  <!-- <button mat-menu-item (click)="openInvoiceDialog()">Számlázási adatok</button> -->
  <button (click)="openChangePasswordDialog()" mat-menu-item>
    {{ 'navbar.edit-password' | translate }}
  </button>
  <button (click)="logOut()" mat-menu-item>
    {{ 'navbar.logout' | translate }}
  </button>
</mat-menu>
