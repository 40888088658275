<div class="flex flex-col items-center h-full text-center text-dark-green w-full">
  <form class="w-full text-sm mb-2 flex items-center" [formGroup]="dateRangeSelectorForm">
    <mat-form-field appearance="outline" class="w-full">
      <mat-date-range-input class="!cursor-pointer" [max]="maxDate" [rangePicker]="picker" (click)="picker.open()">
        <input class="!cursor-pointer" disabled formControlName="startDate" matStartDate placeholder="Start date" />
        <input class="!cursor-pointer" disabled formControlName="endDate" matEndDate placeholder="End date" />
      </mat-date-range-input>
      <mat-hint class="text-dark-green">YYYY. MM. DD. – YYYY. MM. DD.</mat-hint>
      <mat-datepicker-toggle [for]="picker" matIconSuffix />
      <mat-date-range-picker #picker />
    </mat-form-field>
  </form>
  <div class="h-full w-full flex flex-col padding-2 padding-2 rounded-lg">
    <div
      *ngIf="!hasDeviceLocation"
      class="flex flex-col gap-2 h-[95%] w-full justify-center items-center text-dark-green">
      <p>{{ 'dashboard-page.sensor-viewer-panel.no-sensor-data' | translate }}</p>
      <dfarm-button [lightGreenButton]="true" (click)="navigateToDfarmShop()">Webshop</dfarm-button>
    </div>

    <!-- soil temperature -->
    <div
      class="relative bg-off-white shadow-sunkenBoxShadow invisible w-0"
      [ngClass]="{
        'visible-chart': hasDeviceLocation,
        '!h-0': !hasDeviceLocation,
      }">
      <canvas
        id="soilTemperatureCanvas"
        #soilTemperatureCanvas
        [ngClass]="{
          '!h-0': selectedDeviceLocation.deviceType === undefined,
        }"></canvas>
      <div *ngIf="getChartResolution(chartNameEnum.soilTemperatureCanvas).resolutions.length > 1">
        <dfarm-resolution-button-group
          [resolutions]="getChartResolution(chartNameEnum.soilTemperatureCanvas).resolutions"
          (resolutionChanged)="changeChartResolution($event, chartNameEnum.soilTemperatureCanvas)" />
      </div>
    </div>

    <!-- soil moisture -->
    <div
      class="relative bg-off-white shadow-sunkenBoxShadow invisible w-0"
      [ngClass]="{
        'visible-chart': hasDeviceLocation,
        '!h-0': !hasDeviceLocation,
      }">
      <canvas id="soilMoistureCanvas" #soilMoistureCanvas></canvas>
      <div *ngIf="getChartResolution(chartNameEnum.soilMoistureCanvas).resolutions.length > 1">
        <dfarm-resolution-button-group
          [resolutions]="getChartResolution(chartNameEnum.soilMoistureCanvas).resolutions"
          (resolutionChanged)="changeChartResolution($event, chartNameEnum.soilMoistureCanvas)" />
      </div>
    </div>

    <!-- soil potential -->
    <div
      class="relative bg-off-white shadow-sunkenBoxShadow invisible w-0"
      [ngClass]="{
        'visible-chart': hasDeviceLocation,
        '!h-0': !hasDeviceLocation,
      }">
      <canvas id="soilPotentialCanvas" #soilPotentialCanvas></canvas>
      <div *ngIf="getChartResolution(chartNameEnum.soilPotentialCanvas).resolutions.length > 1">
        <dfarm-resolution-button-group
          [resolutions]="getChartResolution(chartNameEnum.soilPotentialCanvas).resolutions"
          (resolutionChanged)="changeChartResolution($event, chartNameEnum.soilPotentialCanvas)" />
      </div>
    </div>
  </div>
</div>
