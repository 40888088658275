import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Chart, registerables } from 'chart.js';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';
import {
  IAgroMonitoringSoilDataViewModel,
  IAgroMonitoringUviDataViewModel,
  IAgroMonitoringWeatherDataViewModel,
  IDateRangeSelectorForm,
} from 'src/app/pages/dashboard/models/agromonitoring-data.model';
import { FieldDetailsViewModel } from 'src/app/pages/dashboard/models/field.model';
import { AgroMonitoringDataService } from 'src/app/pages/dashboard/services/agromonitoring-data.service';
import { DashboardPartialState } from 'src/app/pages/dashboard/state/dashboard.reducer';
import * as DashboardQueries from '../../../../state/dashboard.selectors';

@Component({
  selector: 'dfarm-field-data-details',
  templateUrl: './field-data-details.component.html',
  styleUrls: ['./field-data-details.component.scss'],
})
export class FieldDataDetailsComponent implements OnInit {
  subs: Subscription[] = [];

  formControls: Record<keyof IDateRangeSelectorForm, FormControl> = {
    startDate: new FormControl(''),
    endDate: new FormControl(''),
  };
  dateRangeSelectorForm = new FormGroup(this.formControls);

  selectedField: FieldDetailsViewModel;

  kpiMinimumTemperature: number;
  kpiMaximumTemperature: number;
  kpiPrecipitation: number;

  maxDate = new Date();

  @ViewChild('airTemperatureCanvas', { static: true })
  private airTemperatureCanvas: ElementRef;
  airTemperatureChart: any;

  @ViewChild('cumulativeAverageAirTemperatureCanvas', { static: true })
  private cumulativeAverageAirTemperatureCanvas: ElementRef;
  cumulativeAverageAirTemperatureChart: any;

  @ViewChild('soilTemperatureT0Canvas', { static: true })
  private soilTemperatureT0Canvas: ElementRef;
  soilTemperatureT0Chart: any;

  @ViewChild('cumulativeAverageSoilTemperatureT0Canvas', { static: true })
  private cumulativeAverageSoilTemperatureT0Canvas: ElementRef;
  cumulativeAverageSoilTemperatureT0Chart: any;

  @ViewChild('soilTemperatureT10Canvas', { static: true })
  private soilTemperatureT10Canvas: ElementRef;
  soilTemperatureT10Chart: any;

  @ViewChild('cumulativeAverageSoilTemperatureT10Canvas', { static: true })
  private cumulativeAverageSoilTemperatureT10Canvas: ElementRef;
  cumulativeAverageSoilTemperatureT10Chart: any;

  @ViewChild('precipitationCanvas', { static: true })
  private precipitationCanvas: ElementRef;
  precipitationChart: any;

  @ViewChild('cumulativePrecipitationCanvas', { static: true })
  private cumulativePrecipitationCanvas: ElementRef;
  cumulativePrecipitationChart: any;

  @ViewChild('humidityCanvas', { static: true })
  private humidityCanvas: ElementRef;
  humidityChart: any;

  @ViewChild('uviCanvas', { static: true })
  private uviCanvas: ElementRef;
  uviChart: any;

  weatherData: IAgroMonitoringWeatherDataViewModel;
  soilData: IAgroMonitoringSoilDataViewModel;
  uviData: IAgroMonitoringUviDataViewModel;

  constructor(
    private readonly dashboardStore: Store<DashboardPartialState>,
    private readonly agroMonitoringDataService: AgroMonitoringDataService,
    private readonly translateService: TranslateService,
    private readonly deviceDetectorService: DeviceDetectorService,
  ) {}

  ngOnInit(): void {
    const today = new Date();
    const prev7days = new Date();
    prev7days.setDate(today.getDate() - 6);

    this.formControls.startDate.setValue(prev7days.toISOString().slice(0, 10), {
      emitEvent: false,
    });
    this.formControls.endDate.setValue(today.toISOString().slice(0, 10), {
      emitEvent: false,
    });
    this.subs.push(
      this.dateRangeSelectorForm.valueChanges.subscribe(value => {
        if (!value.startDate || !value.endDate) {
          return;
        }
        this.getAgroMonitoringData();
      }),
    );
    this.subs.push(
      this.dashboardStore.pipe(select(DashboardQueries.getSelectedField)).subscribe(selectedField => {
        if (selectedField === undefined) {
          return;
        }

        this.selectedField = selectedField;

        this.getAgroMonitoringData();
      }),
    );

    Chart.register(...registerables);

    this.airTemperatureChart = new Chart(this.airTemperatureCanvas.nativeElement, {
      type: 'bar',
      data: {
        datasets: [],
      },
      options: {
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          x: {
            offset: true,
            ticks: {
              display: !this.deviceDetectorService.isMobile(),
              color: '#002626',
            },
            title: {
              display: false,
              text: this.getTranslationTitle('date'),
              color: '#002626',
            },
          },
          y: {
            //   min: -20,
            //   max: 50,
            ticks: {
              color: '#002626',
            },
            title: {
              display: false,
              text: this.getTranslationTitle('temperature-with-degree'),
              color: '#002626',
            },
            grid: {
              drawBorder: false,
              color: function (context) {
                return 'black';
              },
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: this.getTranslationTitle('air-temperature'),
            color: '#002626',
            font: {
              size: 16,
            },
            padding: {
              bottom: 5,
            },
          },
          legend: {
            display: !this.deviceDetectorService.isMobile(),
            labels: {
              color: '#002626',
            },
          },
        },
        layout: {
          padding: 10,
        },
      },
    });

    // this.cumulativeAverageAirTemperatureChart = new Chart(
    //   this.cumulativeAverageAirTemperatureCanvas.nativeElement,
    //   {
    //     type: 'line',
    //     data: {
    //       datasets: [],
    //     },
    //     options: {
    //       interaction: {
    //         intersect: false,
    //         mode: 'index',
    //       },
    //       scales: {
    //         x: {
    //           offset: true,
    //           ticks: {
    //             display: !this.deviceDetectorService.isMobile(),
    //             color: '#002626',
    //           },
    //           title: {
    //             display: false,
    //             text: 'Dátum',
    //             color: '#002626',
    //           },
    //         },
    //         y: {
    //           min: 0,
    //           ticks: {
    //             color: '#002626',
    //           },
    //           title: {
    //             display: false,
    //             text: this.getTranslationTitle('temperature-with-degree'),
    //             color: '#002626',
    //           },
    //           grid: {
    //             drawBorder: false,
    //             color: function (context) {
    //               return 'black';
    //             },
    //           },
    //         },
    //       },
    //       plugins: {
    //         title: {
    //           display: true,
    //           text: this.getTranslationTitle('summary-of-air-temperature'),
    //           color: '#002626',
    //           font: {
    //             size: 16,
    //           },
    //           padding: {
    //             bottom: 5,
    //           },
    //         },
    //         legend: {
    //           display: !this.deviceDetectorService.isMobile(),
    //           labels: {
    //             color: '#002626',
    //           },
    //         },
    //       },
    //       layout: {
    //         padding: 10,
    //       },
    //     },
    //   }
    // );

    this.soilTemperatureT0Chart = new Chart(this.soilTemperatureT0Canvas.nativeElement, {
      type: 'bar',
      data: {
        datasets: [],
      },
      options: {
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          x: {
            offset: true,
            ticks: {
              display: !this.deviceDetectorService.isMobile(),
              color: '#002626',
            },
            title: {
              display: false,
              text: this.getTranslationTitle('date'),
              color: '#002626',
            },
          },
          y: {
            min: -20,
            max: 50,
            ticks: {
              color: '#002626',
            },
            title: {
              display: false,
              text: this.getTranslationTitle('temperature-with-degree'),
              color: '#002626',
            },
            grid: {
              drawBorder: false,
              color: function (context) {
                return 'black';
              },
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: this.getTranslationTitle('soil-temperature'),
            color: '#002626',
            font: {
              size: 16,
            },
            padding: {
              bottom: 5,
            },
          },
          legend: {
            display: !this.deviceDetectorService.isMobile(),
            labels: {
              color: '#002626',
            },
          },
        },
        layout: {
          padding: 10,
        },
      },
    });

    // this.cumulativeAverageSoilTemperatureT0Chart = new Chart(
    //   this.cumulativeAverageSoilTemperatureT0Canvas.nativeElement,
    //   {
    //     type: 'line',
    //     data: {
    //       datasets: [],
    //     },
    //     options: {
    //       interaction: {
    //         intersect: false,
    //         mode: 'index',
    //       },
    //       scales: {
    //         x: {
    //           offset: true,
    //           ticks: {
    //             display: !this.deviceDetectorService.isMobile(),
    //             color: '#002626',
    //           },
    //           title: {
    //             display: false,
    //             text: this.getTranslationTitle('date'),
    //             color: '#002626',
    //           },
    //         },
    //         y: {
    //           min: 0,
    //           ticks: {
    //             color: '#002626',
    //           },
    //           title: {
    //             display: false,
    //             text: this.getTranslationTitle('temperature-with-degree'),
    //             color: '#002626',
    //           },
    //           grid: {
    //             drawBorder: false,
    //             color: function (context) {
    //               return 'black';
    //             },
    //           },
    //         },
    //       },
    //       plugins: {
    //         title: {
    //           display: true,
    //           text: this.getTranslationTitle('summary-of-soil-temperature'),
    //           color: '#002626',
    //           font: {
    //             size: 16,
    //           },
    //           padding: {
    //             bottom: 5,
    //           },
    //         },
    //         legend: {
    //           display: !this.deviceDetectorService.isMobile(),
    //           labels: {
    //             color: '#002626',
    //           },
    //         },
    //       },
    //       layout: {
    //         padding: 10,
    //       },
    //     },
    //   }
    // );

    this.soilTemperatureT10Chart = new Chart(this.soilTemperatureT10Canvas.nativeElement, {
      type: 'bar',
      data: {
        datasets: [],
      },
      options: {
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          x: {
            offset: true,
            ticks: {
              display: !this.deviceDetectorService.isMobile(),
              color: '#002626',
            },
            title: {
              display: false,
              text: this.getTranslationTitle('date'),
              color: '#002626',
            },
          },
          y: {
            min: -20,
            max: 50,
            ticks: {
              color: '#002626',
            },
            title: {
              display: false,
              text: this.getTranslationTitle('temperature-with-degree'),
              color: '#002626',
            },
            grid: {
              drawBorder: false,
              color: function (context) {
                return 'black';
              },
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: this.getTranslationTitle('soil-temperature-10'),
            color: '#002626',
            font: {
              size: 16,
            },
            padding: {
              bottom: 5,
            },
          },
          legend: {
            display: !this.deviceDetectorService.isMobile(),
            labels: {
              color: '#002626',
            },
          },
        },
        layout: {
          padding: 10,
        },
      },
    });

    // this.cumulativeAverageSoilTemperatureT10Chart = new Chart(
    //   this.cumulativeAverageSoilTemperatureT10Canvas.nativeElement,
    //   {
    //     type: 'line',
    //     data: {
    //       datasets: [],
    //     },
    //     options: {
    //       interaction: {
    //         intersect: false,
    //         mode: 'index',
    //       },
    //       scales: {
    //         x: {
    //           offset: true,
    //           ticks: {
    //             display: !this.deviceDetectorService.isMobile(),
    //             color: '#002626',
    //           },
    //           title: {
    //             display: false,
    //             text: this.getTranslationTitle('date'),
    //             color: '#002626',
    //           },
    //         },
    //         y: {
    //           min: 0,
    //           ticks: {
    //             color: '#002626',
    //           },
    //           title: {
    //             display: false,
    //             text: this.getTranslationTitle('temperature-with-degree'),
    //             color: '#002626',
    //           },
    //           grid: {
    //             drawBorder: false,
    //             color: function (context) {
    //               return 'black';
    //             },
    //           },
    //         },
    //       },
    //       plugins: {
    //         title: {
    //           display: true,
    //           text: this.getTranslationTitle('summary-of-soil-temperature-10'),
    //           color: '#002626',
    //           font: {
    //             size: 16,
    //           },
    //           padding: {
    //             bottom: 5,
    //           },
    //         },
    //         legend: {
    //           display: !this.deviceDetectorService.isMobile(),
    //           labels: {
    //             color: '#002626',
    //           },
    //         },
    //       },
    //       layout: {
    //         padding: 10,
    //       },
    //     },
    //   }
    // );

    this.precipitationChart = new Chart(this.precipitationCanvas.nativeElement, {
      type: 'bar',
      data: {
        datasets: [],
      },
      options: {
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          x: {
            offset: true,
            ticks: {
              display: !this.deviceDetectorService.isMobile(),
              color: '#002626',
            },
            title: {
              display: false,
              text: this.getTranslationTitle('date'),
              color: '#002626',
            },
          },
          y: {
            min: 0,
            ticks: {
              color: '#002626',
            },
            title: {
              display: false,
              text: this.getTranslationTitle('amount'),
              color: '#002626',
            },
            grid: {
              drawBorder: false,
              color: function (context) {
                return 'black';
              },
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: this.getTranslationTitle('amount-of-rain'),
            color: '#002626',
            font: {
              size: 16,
            },
            padding: {
              bottom: 5,
            },
          },
          legend: {
            display: !this.deviceDetectorService.isMobile(),
            labels: {
              color: '#002626',
            },
          },
        },
        layout: {
          padding: 10,
        },
      },
    });

    this.cumulativePrecipitationChart = new Chart(this.cumulativePrecipitationCanvas.nativeElement, {
      type: 'line',
      data: {
        datasets: [],
      },
      options: {
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          x: {
            offset: true,
            ticks: {
              display: !this.deviceDetectorService.isMobile(),
              color: '#002626',
            },
            title: {
              display: false,
              text: this.getTranslationTitle('date'),
              color: '#002626',
            },
          },
          y: {
            min: 0,
            ticks: {
              color: '#002626',
            },
            title: {
              display: false,
              text: this.getTranslationTitle('amount'),
              color: '#002626',
            },
            grid: {
              drawBorder: false,
              color: function (context) {
                return 'black';
              },
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: this.getTranslationTitle('summary-of-rain'),
            color: '#002626',
            font: {
              size: 16,
            },
            padding: {
              bottom: 5,
            },
          },
          legend: {
            display: !this.deviceDetectorService.isMobile(),
            labels: {
              color: '#002626',
            },
          },
        },
        layout: {
          padding: 10,
        },
      },
    });

    this.humidityChart = new Chart(this.humidityCanvas.nativeElement, {
      type: 'line',
      data: {
        datasets: [],
      },
      options: {
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          x: {
            offset: true,
            ticks: {
              display: !this.deviceDetectorService.isMobile(),
              color: '#002626',
            },
            title: {
              display: false,
              text: this.getTranslationTitle('date'),
              color: '#002626',
            },
          },
          y: {
            min: 0,
            max: 100,
            ticks: {
              color: '#002626',
            },
            title: {
              display: false,
              text: this.getTranslationTitle('humidity'),
              color: '#002626',
            },
            grid: {
              drawBorder: false,
              color: function (context) {
                return 'black';
              },
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: this.getTranslationTitle('humidity'),
            color: '#002626',
            font: {
              size: 16,
            },
            padding: {
              bottom: 5,
            },
          },
          legend: {
            display: !this.deviceDetectorService.isMobile(),
            labels: {
              color: '#002626',
            },
          },
        },
        layout: {
          padding: 10,
        },
      },
    });

    this.uviChart = new Chart(this.uviCanvas.nativeElement, {
      type: 'line',
      data: {
        datasets: [],
      },
      options: {
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          x: {
            offset: true,
            ticks: {
              display: !this.deviceDetectorService.isMobile(),
              color: '#002626',
            },
            title: {
              display: false,
              text: this.getTranslationTitle('date'),
              color: '#002626',
            },
          },
          y: {
            min: 0,
            // max: 100,
            ticks: {
              color: '#002626',
            },
            title: {
              display: false,
              text: this.getTranslationTitle('amount'),
              color: '#002626',
            },
            grid: {
              drawBorder: false,
              color: function (context) {
                return 'black';
              },
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: this.getTranslationTitle('uv'),
            color: '#002626',
            font: {
              size: 16,
            },
            padding: {
              bottom: 5,
            },
          },
          legend: {
            display: !this.deviceDetectorService.isMobile(),
            labels: {
              color: '#002626',
            },
          },
        },
        layout: {
          padding: 10,
        },
      },
    });
  }

  private getAgroMonitoringData(): void {
    if (this.dateRangeSelectorForm.valid && typeof this.selectedField !== 'undefined') {
      const intervalStart = new Date(this.formControls.startDate.value);
      const intervalEnd = new Date(this.formControls.endDate.value);

      this.agroMonitoringDataService
        .getAgroMonitoringWeatherData(this.selectedField.id, intervalStart, intervalEnd)
        .subscribe(weatherData => {
          this.weatherData = weatherData;

          this.updateAirTemperatureChart();
          // this.updateCumulativeAverageAirTemperatureChart();
          this.updatePrecipitationChart();
          this.updateCumulativePrecipitationChart();
          this.updateHumidityChart();
          this.updateKpiValues();
        });

      this.agroMonitoringDataService
        .getAgroMonitoringSoilData(this.selectedField.id, intervalStart, intervalEnd)
        .subscribe(res => {
          this.soilData = res;

          this.updateSoilTemperatureT0Chart();
          // this.updateCumulativeAverageSoilTemperatureT0Chart();
          this.updateSoilTemperatureT10Chart();
          // this.updateCumulativeAverageSoilTemperatureT10Chart();
        });

      this.agroMonitoringDataService
        .getAgroMonitoringUviData(this.selectedField.id, intervalStart, intervalEnd)
        .subscribe(res => {
          this.uviData = res;

          this.updateUviChart();
        });
    }
  }

  updateAirTemperatureChart() {
    const newLabels = this.weatherData.dataPoints.map(dataPoint => dataPoint.datetime.toLocaleDateString());

    const newDatasets = [
      {
        label: this.getTranslationTitle('maximum'),
        data: this.weatherData.dataPoints.map(dataPoint => dataPoint.temperature.maximum),
        borderColor: 'rgba(255, 0, 0, 0.8)',
        backgroundColor: 'rgba(255, 0, 0, 0.6)',
      },
      {
        label: this.getTranslationTitle('average'),
        data: this.weatherData.dataPoints.map(dataPoint => dataPoint.temperature.average),
        borderColor: 'rgba(255, 128, 0, 0.8)',
        backgroundColor: 'rgba(255, 128, 0, 0.6)',
      },
      {
        label: this.getTranslationTitle('minimum'),
        data: this.weatherData.dataPoints.map(dataPoint => dataPoint.temperature.minimum),
        borderColor: 'rgba(0, 128, 255, 0.8)',
        backgroundColor: 'rgba(0, 128, 255, 0.6)',
      },
    ];

    this.airTemperatureChart.data.labels = [];
    this.airTemperatureChart.data.datasets = [];

    newLabels.forEach(label => {
      this.airTemperatureChart.data.labels.push(label);
    });
    newDatasets.forEach(dataset => {
      this.airTemperatureChart.data.datasets.push(dataset);
    });

    this.airTemperatureChart.update();
  }

  updateCumulativeAverageAirTemperatureChart() {
    const newLabels = this.weatherData.dataPoints.map(dataPoint => dataPoint.datetime.toLocaleDateString());

    const findCumulativeSum = arr => {
      const creds = arr.reduce(
        (acc, val) => {
          let { sum, res } = acc;
          if (!isNaN(val)) {
            sum += val;
            res.push(sum);
          } else {
            res.push(NaN);
          }
          return { sum, res };
        },
        {
          sum: 0,
          res: [],
        },
      );
      return creds.res;
    };

    const newDatasets = [
      {
        label: this.getTranslationTitle('summary-of-average-temperature'),
        data: findCumulativeSum(this.weatherData.dataPoints.map(dataPoint => dataPoint.temperature.average)),
        borderColor: 'rgba(255, 128, 0, 0.8)',
        backgroundColor: 'rgba(255, 128, 0, 0.6)',
        fill: true,
      },
    ];

    this.cumulativeAverageAirTemperatureChart.data.labels = [];
    this.cumulativeAverageAirTemperatureChart.data.datasets = [];

    newLabels.forEach(label => {
      this.cumulativeAverageAirTemperatureChart.data.labels.push(label);
    });
    newDatasets.forEach(dataset => {
      this.cumulativeAverageAirTemperatureChart.data.datasets.push(dataset);
    });

    this.cumulativeAverageAirTemperatureChart.update();
  }

  updateSoilTemperatureT0Chart() {
    const newLabels = this.soilData.dataPoints.map(dataPoint => dataPoint.datetime.toLocaleDateString());

    const newDatasets = [
      {
        label: this.getTranslationTitle('maximum'),
        data: this.soilData.dataPoints.map(dataPoint => dataPoint.temperatureSurface.maximum),
        borderColor: 'rgba(255, 0, 0, 0.8)',
        backgroundColor: 'rgba(255, 0, 0, 0.6)',
      },
      {
        label: this.getTranslationTitle('average'),
        data: this.soilData.dataPoints.map(dataPoint => dataPoint.temperatureSurface.average),
        borderColor: 'rgba(255, 128, 0, 0.8)',
        backgroundColor: 'rgba(255, 128, 0, 0.6)',
      },
      {
        label: this.getTranslationTitle('minimum'),
        data: this.soilData.dataPoints.map(dataPoint => dataPoint.temperatureSurface.minimum),
        borderColor: 'rgba(0, 128, 255, 0.8)',
        backgroundColor: 'rgba(0, 128, 255, 0.6)',
      },
    ];

    this.soilTemperatureT0Chart.data.labels = [];
    this.soilTemperatureT0Chart.data.datasets = [];

    newLabels.forEach(label => {
      this.soilTemperatureT0Chart.data.labels.push(label);
    });
    newDatasets.forEach(dataset => {
      this.soilTemperatureT0Chart.data.datasets.push(dataset);
    });

    this.soilTemperatureT0Chart.update();
  }

  updateCumulativeAverageSoilTemperatureT0Chart() {
    const newLabels = this.soilData.dataPoints.map(dataPoint => dataPoint.datetime.toLocaleDateString());
    const findCumulativeSum = arr => {
      const creds = arr.reduce(
        (acc, val) => {
          let { sum, res } = acc;
          if (!isNaN(val)) {
            sum += val;
          }
          res.push(sum);
          return { sum, res };
        },
        {
          sum: 0,
          res: [],
        },
      );
      return creds.res;
    };
    const newDatasets = [
      {
        label: this.getTranslationTitle('summary-of-average-temperature'),
        data: findCumulativeSum(this.soilData.dataPoints.map(dataPoint => dataPoint.temperatureSurface.average)),
        borderColor: 'rgba(255, 128, 0, 0.8)',
        backgroundColor: 'rgba(255, 128, 0, 0.6)',
        fill: true,
      },
    ];
    this.cumulativeAverageSoilTemperatureT0Chart.data.labels = [];
    this.cumulativeAverageSoilTemperatureT0Chart.data.datasets = [];
    newLabels.forEach(label => {
      this.cumulativeAverageSoilTemperatureT0Chart.data.labels.push(label);
    });
    newDatasets.forEach(dataset => {
      this.cumulativeAverageSoilTemperatureT0Chart.data.datasets.push(dataset);
    });
    this.cumulativeAverageSoilTemperatureT0Chart.update();
  }

  updateSoilTemperatureT10Chart() {
    const newLabels = this.soilData.dataPoints.map(dataPoint => dataPoint.datetime.toLocaleDateString());

    const newDatasets = [
      {
        label: this.getTranslationTitle('maximum'),
        data: this.soilData.dataPoints.map(dataPoint => dataPoint.temperatureDepth10.maximum),
        borderColor: 'rgba(255, 0, 0, 0.8)',
        backgroundColor: 'rgba(255, 0, 0, 0.6)',
      },
      {
        label: this.getTranslationTitle('average'),
        data: this.soilData.dataPoints.map(dataPoint => dataPoint.temperatureDepth10.average),
        borderColor: 'rgba(255, 128, 0, 0.8)',
        backgroundColor: 'rgba(255, 128, 0, 0.6)',
      },
      {
        label: this.getTranslationTitle('minimum'),
        data: this.soilData.dataPoints.map(dataPoint => dataPoint.temperatureDepth10.minimum),
        borderColor: 'rgba(0, 128, 255, 0.8)',
        backgroundColor: 'rgba(0, 128, 255, 0.6)',
      },
    ];

    this.soilTemperatureT10Chart.data.labels = [];
    this.soilTemperatureT10Chart.data.datasets = [];

    newLabels.forEach(label => {
      this.soilTemperatureT10Chart.data.labels.push(label);
    });
    newDatasets.forEach(dataset => {
      this.soilTemperatureT10Chart.data.datasets.push(dataset);
    });

    this.soilTemperatureT10Chart.update();
  }

  updateCumulativeAverageSoilTemperatureT10Chart() {
    const newLabels = this.soilData.dataPoints.map(dataPoint => dataPoint.datetime.toLocaleDateString());
    const findCumulativeSum = arr => {
      const creds = arr.reduce(
        (acc, val) => {
          let { sum, res } = acc;
          if (!isNaN(val)) {
            sum += val;
          }
          res.push(sum);
          return { sum, res };
        },
        {
          sum: 0,
          res: [],
        },
      );
      return creds.res;
    };
    const newDatasets = [
      {
        label: this.getTranslationTitle('summary-of-average-temperature'),
        data: findCumulativeSum(this.soilData.dataPoints.map(dataPoint => dataPoint.temperatureDepth10.average)),
        borderColor: 'rgba(255, 128, 0, 0.8)',
        backgroundColor: 'rgba(255, 128, 0, 0.6)',
        fill: true,
      },
    ];
    this.cumulativeAverageSoilTemperatureT10Chart.data.labels = [];
    this.cumulativeAverageSoilTemperatureT10Chart.data.datasets = [];
    newLabels.forEach(label => {
      this.cumulativeAverageSoilTemperatureT10Chart.data.labels.push(label);
    });
    newDatasets.forEach(dataset => {
      this.cumulativeAverageSoilTemperatureT10Chart.data.datasets.push(dataset);
    });
    this.cumulativeAverageSoilTemperatureT10Chart.update();
  }

  updatePrecipitationChart() {
    const newLabels = this.weatherData.dataPoints.map(dataPoint => dataPoint.datetime.toLocaleDateString());

    const newDatasets = [
      {
        label: this.getTranslationTitle('amount-without-mm'),
        data: this.weatherData.dataPoints.map(dataPoint =>
          dataPoint.precipitation.sum ? dataPoint.precipitation.sum : null,
        ),
        borderColor: 'rgba(0, 128, 255, 0.8)',
        backgroundColor: 'rgba(0, 128, 255, 0.6)',
      },
    ];

    this.precipitationChart.data.labels = [];
    this.precipitationChart.data.datasets = [];

    newLabels.forEach(label => {
      this.precipitationChart.data.labels.push(label);
    });
    newDatasets.forEach(dataset => {
      this.precipitationChart.data.datasets.push(dataset);
    });

    this.precipitationChart.update();
  }

  updateCumulativePrecipitationChart() {
    const newLabels = this.weatherData.dataPoints.map(dataPoint => dataPoint.datetime.toLocaleDateString());

    const findCumulativeSum = arr => {
      const creds = arr.reduce(
        (acc, val) => {
          let { sum, res } = acc;
          if (!isNaN(val)) {
            sum += val;
            res.push(sum);
          } else {
            res.push(NaN);
          }
          return { sum, res };
        },
        {
          sum: 0,
          res: [],
        },
      );
      return creds.res;
    };

    const newDatasets = [
      {
        label: this.getTranslationTitle('summary-of-amount'),
        data: findCumulativeSum(this.weatherData.dataPoints.map(dataPoint => dataPoint.precipitation.sum)),
        borderColor: 'rgba(0, 128, 255, 0.8)',
        backgroundColor: 'rgba(0, 128, 255, 0.6)',
        fill: true,
      },
    ];

    this.cumulativePrecipitationChart.data.labels = [];
    this.cumulativePrecipitationChart.data.datasets = [];

    newLabels.forEach(label => {
      this.cumulativePrecipitationChart.data.labels.push(label);
    });
    newDatasets.forEach(dataset => {
      this.cumulativePrecipitationChart.data.datasets.push(dataset);
    });

    this.cumulativePrecipitationChart.update();
  }

  updateHumidityChart() {
    const newLabels = this.weatherData.dataPoints.map(dataPoint => dataPoint.datetime.toLocaleDateString());
    const newDatasets = [
      {
        label: this.getTranslationTitle('maximum'),
        data: this.weatherData.dataPoints.map(dataPoint => dataPoint.humidity.maximum),
        borderColor: 'rgba(255, 0, 0, 0.8)',
        backgroundColor: 'rgba(255, 0, 0, 0.6)',
      },
      {
        label: this.getTranslationTitle('average'),
        data: this.weatherData.dataPoints.map(dataPoint => dataPoint.humidity.average),
        borderColor: 'rgba(255, 128, 0, 0.8)',
        backgroundColor: 'rgba(255, 128, 0, 0.6)',
      },
      {
        label: this.getTranslationTitle('minimum'),
        data: this.weatherData.dataPoints.map(dataPoint => dataPoint.humidity.minimum),
        borderColor: 'rgba(0, 128, 255, 0.8)',
        backgroundColor: 'rgba(0, 128, 255, 0.6)',
      },
    ];

    this.humidityChart.data.labels = [];
    this.humidityChart.data.datasets = [];

    newLabels.forEach(label => {
      this.humidityChart.data.labels.push(label);
    });
    newDatasets.forEach(dataset => {
      this.humidityChart.data.datasets.push(dataset);
    });

    this.humidityChart.update();
  }

  updateUviChart() {
    const newLabels = this.uviData.dataPoints.map(dataPoint => dataPoint.datetime.toLocaleDateString());
    const newDatasets = [
      {
        label: this.getTranslationTitle('value'),
        data: this.uviData.dataPoints.map(dataPoint => dataPoint.uvi),
        borderColor: 'rgba(255, 128, 0, 0.8)',
        backgroundColor: 'rgba(255, 128, 0, 0.6)',
      },
    ];

    this.uviChart.data.labels = [];
    this.uviChart.data.datasets = [];

    newLabels.forEach(label => {
      this.uviChart.data.labels.push(label);
    });
    newDatasets.forEach(dataset => {
      this.uviChart.data.datasets.push(dataset);
    });

    this.uviChart.update();
  }

  private updateKpiValues(): void {
    this.kpiMinimumTemperature =
      this.weatherData.dataPoints[this.weatherData.dataPoints.length - 1].temperature.minimum;
    this.kpiMaximumTemperature =
      this.weatherData.dataPoints[this.weatherData.dataPoints.length - 1].temperature.maximum;
    this.kpiPrecipitation = this.weatherData.dataPoints[this.weatherData.dataPoints.length - 1].precipitation.sum;
  }

  private getTranslationTitle = (translationPath: string): string =>
    this.translateService.instant(`dashboard-page.data-viewer-panel.${translationPath}`);
}
