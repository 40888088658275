import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatSliderModule } from '@angular/material/slider';
import { MatStepperModule } from '@angular/material/stepper';
import { StoreModule } from '@ngrx/store';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from 'src/app/shared/utils/translate.factory';
import { environment } from 'src/environments/environment';
import { SharedModule } from '../../shared/shared.module';
import { AddFieldComponent } from './components/add-field/add-field.component';
import { AddFieldWithGpsComponent } from './components/add-field/components/add-field-with-gps/add-field-with-gps.component';
import { AddFieldWithMapComponent } from './components/add-field/components/add-field-with-map/add-field-with-map.component';
import { BasePanelComponent } from './components/base-panel.component';
import { FieldDataDetailsComponent } from './components/data-viewer/components/field-data-details/field-data-details.component';
import { DataViewerComponent } from './components/data-viewer/data-viewer.component';
import { DfarmServicesComponent } from './components/dfarm-services/dfarm-services.component';
import { EditFieldDialogComponent } from './components/field-inspector/components/edit-field-dialog/edit-field-dialog.component';
import { FieldOverviewComponent } from './components/field-inspector/components/field-overview/field-overview.component';
import { ParcellDetailsComponent } from './components/field-inspector/components/parcell-details/parcell-details.component';
import { ReportComponent } from './components/field-inspector/components/report/report.component';
import { FieldInspectorComponent } from './components/field-inspector/field-inspector.component';
import { MenuComponent } from './components/menu/menu.component';
import { SatelliteDataDetailsComponent } from './components/satellite-viewer/components/satellite-data-details/satellite-data-details.component';
import { SatelliteDataOverviewComponent } from './components/satellite-viewer/components/satellite-data-overview/satellite-data-overview.component';
import { SatelliteViewerComponent } from './components/satellite-viewer/satellite-viewer.component';
import { ResolutionButtonGroupComponent } from './components/sensor-viewer/components/resolution-button-group/resolution-button-group.component';
import { SensorViewerDetailsComponent } from './components/sensor-viewer/components/sensor-viewer-details/sensor-viewer-details.component';
import { SensorViewerComponent } from './components/sensor-viewer/sensor-viewer.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AgroMonitoringDataService } from './services/agromonitoring-data.service';
import { DashboardService } from './services/dashboard.service';
import { SensorDataService } from './services/sensor-data.service';
import * as fromDashboard from './state/dashboard.reducer';
import { NavbarComponent } from '../../shared/components/navbar/navbar.component';
import { PanelComponent } from '../../shared/components/panel/panel.component';
import { ButtonComponent } from '../../shared/components/button/button.component';
import { WaterscopeItemListComponent } from './components/waterscope/waterscope-item-list/waterscope-item-list.component';
import { WaterscopeTableComponent } from './components/waterscope/waterscope-table/waterscope-table.component';
import { WaterscopeChartsComponent } from './components/waterscope/waterscope-charts/waterscope-charts.component';
import { SensorViewerDetailsComponentV2 } from './components/sensor-viewer/components/sensor-viewer-details-v2/sensor-viewer-details.component-v2';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { SoilProbeComponent } from './components/soil-probe/soil-probe.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';

@NgModule({
  declarations: [
    DashboardComponent,
    FieldInspectorComponent,
    AddFieldComponent,
    MenuComponent,
    AddFieldWithMapComponent,
    AddFieldWithGpsComponent,
    FieldOverviewComponent,
    EditFieldDialogComponent,
    DfarmServicesComponent,
    ParcellDetailsComponent,
    ReportComponent,
    DataViewerComponent,
    FieldDataDetailsComponent,
    SatelliteViewerComponent,
    SatelliteDataOverviewComponent,
    SatelliteDataDetailsComponent,
    SensorViewerComponent,
    SensorViewerDetailsComponent,
    SensorViewerDetailsComponentV2,
    BasePanelComponent,
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    MatStepperModule,
    MatSliderModule,
    SharedModule,
    NavbarComponent,
    PanelComponent,
    ResolutionButtonGroupComponent,
    StoreModule.forFeature(fromDashboard.DASHBOARD_FEATURE_KEY, fromDashboard.reducer),
    TranslateModule.forChild({
      defaultLanguage: localStorage.getItem('translate') ?? environment.defaultLanguage,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ButtonComponent,
    WaterscopeItemListComponent,
    WaterscopeTableComponent,
    WaterscopeChartsComponent,
    MatDividerModule,
    MatIconModule,
    MatDatepickerModule,
    SoilProbeComponent,
    MatFormFieldModule,
    MatNativeDateModule,
  ],
  providers: [DashboardService, AgroMonitoringDataService, SensorDataService],
})
export class DashboardModule {}
