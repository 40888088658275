<div class="login__container">
  <div *ngIf="!isRegisteredSuccessfully && !isRegisterFailed" class="login__container__inner">
    <div class="logo">
      <img alt="logo" src="../../../../../assets/dfarm-logo-2.svg" />
    </div>
    <h1 class="registration-title">
      {{ 'registration-page.registration' | translate }}
    </h1>
    <form [formGroup]="registerFormGroup">
      <div class="register-form">
        <div class="left-form">
          <div class="form-title">
            {{ 'registration-page.personal-data' | translate }}
          </div>
          <div class="client-name-wrapper">
            <div class="input-wrapper">
              <label
                class="field-name-label"
                [ngClass]="{
                  'error-text':
                    (formControls.lastName.touched && formControls.lastName.invalid) ||
                    (isSubmitted && formControls.lastName.invalid),
                }">
                {{ 'registration-page.last-name' | translate }}*
              </label>
              <input
                name="name"
                type="text"
                [ngClass]="{
                  'error-input':
                    (formControls.lastName.touched && formControls.lastName.invalid) ||
                    (isSubmitted && formControls.lastName.invalid),
                }"
                [formControl]="formControls.lastName"
                placeholder="Kiss" />
            </div>
            <div class="input-wrapper">
              <label
                class="field-name-label"
                [ngClass]="{
                  'error-text':
                    (formControls.firstName.touched && formControls.firstName.invalid) ||
                    (isSubmitted && formControls.firstName.invalid),
                }">
                {{ 'registration-page.first-name' | translate }}*
              </label>
              <input
                name="name"
                type="text"
                [ngClass]="{
                  'error-input':
                    (formControls.firstName.touched && formControls.firstName.invalid) ||
                    (isSubmitted && formControls.firstName.invalid),
                }"
                [formControl]="formControls.firstName"
                placeholder="József" />
            </div>
          </div>
          <div class="input-wrapper">
            <label
              class="field-name-label"
              [ngClass]="{
                'error-text':
                  (formControls.email.touched && formControls.email.invalid) ||
                  (isSubmitted && formControls.email.invalid),
              }">
              {{ 'registration-page.email' | translate }}*
            </label>
            <input
              name="email"
              type="email"
              [ngClass]="{
                'error-input':
                  (formControls.email.touched && formControls.email.invalid) ||
                  (isSubmitted && formControls.email.invalid),
              }"
              [formControl]="formControls.email"
              placeholder="valaki@pelda.hu" />
          </div>
          <div class="input-wrapper">
            <label
              class="field-name-label"
              [ngClass]="{
                'error-text':
                  (formControls.emailAgain.touched && formControls.emailAgain.invalid) ||
                  (isSubmitted && formControls.emailAgain.invalid),
              }">
              {{ 'registration-page.confirm-email' | translate }}*
            </label>
            <input
              name="emailAgain"
              type="email"
              [ngClass]="{
                'error-input':
                  (formControls.emailAgain.touched && formControls.emailAgain.invalid) ||
                  formControls.email.value !== formControls.emailAgain.value ||
                  (isSubmitted && formControls.emailAgain.invalid),
              }"
              [formControl]="formControls.emailAgain"
              placeholder="valaki@pelda.hu" />
          </div>
          <div
            *ngIf="
              (formControls.emailAgain.touched && formControls.emailAgain.value !== formControls.email.value) ||
              (isSubmitted && formControls.emailAgain.value !== formControls.email.value)
            "
            class="password-requirement">
            <div>
              {{ 'registration-page.not-matching-email' | translate }}
            </div>
          </div>
        </div>
        <div class="right-form">
          <div class="form-title"></div>
          <div class="input-wrapper">
            <label
              class="field-name-label"
              [ngClass]="{
                'error-text':
                  (formControls.phoneNumber.touched && formControls.phoneNumber.invalid) ||
                  (isSubmitted && formControls.phoneNumber.invalid),
              }">
              {{ 'registration-page.phone-number' | translate }}*
            </label>
            <input
              name="phone"
              type="text"
              [ngClass]="{
                'error-input':
                  (formControls.phoneNumber.touched && formControls.phoneNumber.invalid) ||
                  (isSubmitted && formControls.phoneNumber.invalid),
              }"
              [formControl]="formControls.phoneNumber"
              placeholder="+36 20 123 4567" />
          </div>
          <div class="input-wrapper">
            <label
              class="field-name-label"
              [ngClass]="{
                'error-text':
                  (formControls.password.touched && formControls.password.invalid) ||
                  (isSubmitted && formControls.password.invalid),
              }">
              {{ 'registration-page.password' | translate }}*
            </label>
            <input
              name="password"
              type="password"
              [ngClass]="{
                'error-input':
                  (formControls.password.touched && formControls.password.invalid) ||
                  (isSubmitted && formControls.password.invalid),
              }"
              [formControl]="formControls.password"
              placeholder="********" />
          </div>
          <div
            *ngIf="
              (formControls.password.touched && formControls.password.invalid) ||
              (isSubmitted && formControls.password.invalid)
            "
            class="password-requirement">
            <div>
              {{ 'registration-page.password-requirements' | translate }}
            </div>
            <ul>
              <li>
                {{ 'registration-page.min-8-character' | translate }}
              </li>
              <li>
                {{ 'registration-page.lower-and-upper-case-characters' | translate }}
              </li>
              <li>
                {{ 'registration-page.min-number' | translate }}
              </li>
              <li>
                {{ 'registration-page.min-special-character' | translate }}
              </li>
            </ul>
          </div>
          <div class="input-wrapper">
            <label
              class="field-name-label"
              [ngClass]="{
                'error-text':
                  (formControls.passwordAgain.touched && formControls.passwordAgain.invalid) ||
                  (isSubmitted && formControls.passwordAgain.invalid),
              }">
              {{ 'registration-page.confirm-password' | translate }}*
            </label>
            <input
              name="passwordAgain"
              type="password"
              [ngClass]="{
                'error-input':
                  (formControls.passwordAgain.touched &&
                    (formControls.passwordAgain.invalid ||
                      formControls.password.value !== formControls.passwordAgain.value)) ||
                  (isSubmitted && formControls.passwordAgain.invalid),
              }"
              [formControl]="formControls.passwordAgain"
              placeholder="********" />
          </div>
          <div
            *ngIf="
              (formControls.passwordAgain.touched &&
                formControls.passwordAgain.value !== formControls.password.value) ||
              (isSubmitted && formControls.passwordAgain.value !== formControls.password.value)
            "
            class="password-requirement">
            <div>
              {{ 'registration-page.not-matching-password' | translate }}
            </div>
          </div>
        </div>
      </div>
      <dfarm-activity-type-selector [chipIsRemovable]="true" (emitSelectedActivityTypes)="updateActivityType($event)" />
      <div class="custom-checkbox pb-3">
        <input id="aszf" name="aszf" type="checkbox" [formControl]="formControls.termsAndConditions" />
        <label
          [ngClass]="{
            'error-text':
              (formControls.termsAndConditions.touched && formControls.termsAndConditions.invalid) ||
              (isSubmitted && formControls.termsAndConditions.invalid),
          }"
          for="aszf">
          <a href="../../../../../assets/dFarm-aszf-1.1.pdf" target="_blank">
            {{ 'registration-page.t&c' | translate }}</a
          >

          {{ 'registration-page.accept' | translate }}*</label
        >
      </div>
    </form>
    <div class="flex flex-col gap-2">
      <dfarm-button [outlineButton]="true" (click)="register()">
        {{ 'registration-page.registration' | translate }}
      </dfarm-button>
      <dfarm-button [ghostButton]="true" (click)="navigateToLoginPage()">
        {{ 'registration-page.login' | translate }}
      </dfarm-button>
      <dfarm-language-selector></dfarm-language-selector>
    </div>
  </div>
  <div *ngIf="isRegisteredSuccessfully && !isRegisterFailed" class="login__container__inner">
    <div class="logo mb-4">
      <img alt="Success" src="../../../../../assets/utility-icons/checked.svg" />
    </div>
    <div class="w-full text-center mb-4 text-2xl font-semibold">
      {{ 'registration-page.succesful-registration' | translate }}
    </div>
    <div class="w-full text-center mb-4">
      {{ 'registration-page.please-confirm-email' | translate }}
    </div>
    <dfarm-button class="mb-4" [outlineButton]="true" (click)="navigateToLoginPage()">
      {{ 'registration-page.login' | translate }}
    </dfarm-button>
    <dfarm-button [lightGreenButton]="true" (click)="resendConfirmationEmail()">
      {{ 'registration-page.resend-email' | translate }}
    </dfarm-button>
  </div>
  <div *ngIf="isRegisterFailed && !isRegisteredSuccessfully" class="login__container__inner">
    <div class="logo mb-4">
      <img alt="Fail" src="../../../../../assets/utility-icons/error.svg" />
    </div>
    <div class="w-full text-center mb-4 text-2xl font-semibold">
      {{ 'registration-page.unsuccessful-registration' | translate }}
    </div>
    <div class="w-full text-center mb-4">
      {{ 'registration-page.unknown-error' | translate }}
    </div>
    <dfarm-button [ghostButton]="true" (click)="navigateToRegisterPage()">
      {{ 'registration-page.registration' | translate }}
    </dfarm-button>
  </div>
</div>
