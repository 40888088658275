<div class="input-wrapper">
  <label class="field-name-label text-xl">
    {{ 'dashboard-page.field-inspector-panel.field-name' | translate }}
  </label>
  <input name="fieldName" type="text" [formControl]="fieldName" />
</div>
<div class="buttons">
  <dfarm-button [darkGreenButton]="true" [ghostButton]="true" (click)="cancel()">
    {{ 'dashboard-page.field-inspector-panel.cancel' | translate }}
  </dfarm-button>
  <dfarm-button [lightGreenButton]="true" (click)="save()">
    {{ 'dashboard-page.field-inspector-panel.save' | translate }}
  </dfarm-button>
</div>
