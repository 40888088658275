<dfarm-panel
  *ngIf="!isLoading && hasPermission"
  [isVisible]="panelIsOpen"
  [isLeftSide]="false"
  [wrapperIsBlock]="false"
  [isClosable]="true"
  [isMultiViewPanel]="true">
  <div class="title text-dark-green text-xl">
    {{ 'dashboard-page.satellite-viewer-panel.satellite-data' | translate }}
  </div>
  <!-- Különböző diagramok amik mutatják a műholdas adatokat -->
  <div *ngIf="fields.length > 0" class="p-4 pt-0 h-[calc(100%-2*1rem-28px)] w-full">
    <div class="flex-col justify-between h-full">
      <div class="flex justify-between items-center mb-4">
        <dfarm-button (click)="selectPreviousField()">
          <img alt="left-arrow" class="h-7" src="assets/quick-viewer-icons/navi-left.svg" height="38" width="15" />
        </dfarm-button>
        <div *ngIf="selectedField !== undefined" class="text-xl text-center text-dark-green">
          {{ selectedField.name }}
        </div>
        <dfarm-button (click)="selectNextField()">
          <img alt="right-arrow" class="h-7" src="assets/quick-viewer-icons/navi-right.svg" height="38" width="15" />
        </dfarm-button>
      </div>
      <!-- <mat-tab-group>
        <mat-tab label="Áttekintés">
          <div
            class="w-full h-full"
          >
            <dfarm-satellite-data-overview></dfarm-satellite-data-overview>
          </div>
        </mat-tab>
        <mat-tab label="Részletek">
          <div
            class="w-full h-full"
          >
            <dfarm-satellite-data-details></dfarm-satellite-data-details>
          </div>
        </mat-tab>
      </mat-tab-group> -->

      <div class="w-full h-[calc(100%-2rem)]">
        <dfarm-satellite-data-details />
      </div>
    </div>
  </div>

  <!-- Ez a rész akkor jelenik meg, ha egyetlen földje sincs a felhasznólnak -->
  <div *ngIf="fields.length === 0" class="field-list">
    <div class="flex flex-col justify-center items-center gap-1 h-full text-dark-green">
      <div>
        {{ 'dashboard-page.field-inspector-panel.no-fields' | translate }}
      </div>
      <div>
        {{ 'dashboard-page.field-inspector-panel.please-add-new-field' | translate }}
      </div>
      <button class="light-button w-fit h-fit py-1.5 px-2 rounded-3xl" (click)="showAddFieldPanel()">
        {{ 'dashboard-page.field-inspector-panel.add-new-field' | translate }}
      </button>
    </div>
  </div>
</dfarm-panel>
