import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  signal,
  SimpleChanges,
  WritableSignal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasePanelComponent } from '../../base-panel.component';
import { PanelComponent } from '../../../../../shared/components/panel/panel.component';
import { select, Store } from '@ngrx/store';
import * as CoreQueries from '../../../../../core/state/core.selectors';
import { CorePartialState } from '../../../../../core/state/core.reducer';
import { DashboardPartialState } from '../../../state/dashboard.reducer';
import { MatTableModule } from '@angular/material/table';
import { IDateRangeSelectorForm } from '../../../models/agromonitoring-data.model';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';

export interface Irriagation {
  fieldName: string;
  irrigatedAmount: number;
}

const ELEMENT_DATA: Irriagation[] = [
  { fieldName: 'Tábla 1', irrigatedAmount: 1.0079 },
  { fieldName: 'Tábla 2', irrigatedAmount: 4.0026 },
  { fieldName: 'Tábla 3', irrigatedAmount: 6.941 },
  { fieldName: 'Tábla 4', irrigatedAmount: 9.0122 },
  { fieldName: 'Tábla 5', irrigatedAmount: 10.811 },
  { fieldName: 'Tábla 6', irrigatedAmount: 12.0107 },
  { fieldName: 'Tábla 7', irrigatedAmount: 14.0067 },
  { fieldName: 'Tábla 8', irrigatedAmount: 15.9994 },
  { fieldName: 'Tábla 9', irrigatedAmount: 18.9984 },
];

@Component({
  selector: 'dfarm-waterscope-table',
  standalone: true,
  imports: [CommonModule, PanelComponent, MatTableModule, ReactiveFormsModule, MatInputModule, MatDatepickerModule],
  templateUrl: './waterscope-table.component.html',
  styleUrl: './waterscope-table.component.scss',
})
export class WaterscopeTableComponent extends BasePanelComponent implements OnInit, OnChanges {
  @Input() intervalStart: Date;
  @Input() intervalEnd: Date;
  @Input() maxDate: string;
  @Output() intervalStartChange = new EventEmitter<Date>();
  @Output() intervalEndChange = new EventEmitter<Date>();

  displayedColumns: string[] = ['fieldName', 'irrigatedAmount'];
  dataSource = ELEMENT_DATA;

  subs: Subscription[] = [];

  formControls: Record<keyof IDateRangeSelectorForm, FormControl> = {
    startDate: new FormControl(new Date()),
    endDate: new FormControl(new Date()),
  };
  dateRangeSelectorForm = new FormGroup(this.formControls);

  intervalStartChangeEmitted = false;
  intervalEndChangeEmitted = false;

  totalIrrigatedAmount: WritableSignal<number> = signal(0);
  private readonly coreStore = inject(Store<CorePartialState>);
  private readonly dashboardStore: Store<DashboardPartialState> = inject(Store<DashboardPartialState>);

  constructor() {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // for (const propName in changes) {
    //   const changedProp = changes[propName];
    //
    //   if (propName === 'intervalStart' && changedProp.currentValue !== undefined) {
    //     if (!this.intervalStartChangeEmitted) {
    //       this.formControls.startDate.setValue(this.intervalStart.toISOString().slice(0, 10), {
    //         emitEvent: false,
    //       });
    //     } else {
    //       this.intervalStartChangeEmitted = false;
    //     }
    //   } else if (propName === 'intervalEnd' && changedProp.currentValue !== undefined) {
    //     if (!this.intervalEndChangeEmitted) {
    //       this.formControls.endDate.setValue(this.intervalEnd.toISOString().slice(0, 10), {
    //         emitEvent: false,
    //       });
    //     } else {
    //       this.intervalEndChangeEmitted = false;
    //     }
    //   }
    //
    //   this._calculateTotalIrrigatedAmount();
    // }
  }

  ngOnInit(): void {
    this.coreStore.pipe(select(CoreQueries.getWaterscopeTablePanelIsOpen)).subscribe(panelIsOpen => {
      this.panelIsOpen = panelIsOpen;
      this.isLoading = false;
    });

    this.subs.push(
      this.dateRangeSelectorForm.valueChanges.subscribe(value => {
        if (!value.startDate || !value.endDate) {
          return;
        }

        this.intervalStart = new Date(this.formControls.startDate.value);
        this.intervalStartChange.emit(this.intervalStart);
        this.intervalStartChangeEmitted = true;

        this.intervalEnd = new Date(this.formControls.endDate.value);
        this.intervalEndChange.emit(this.intervalEnd);
        this.intervalEndChangeEmitted = true;
      }),
    );
    this._calculateTotalIrrigatedAmount();
  }

  private _calculateTotalIrrigatedAmount(): void {
    this.totalIrrigatedAmount.set(this.dataSource.reduce((acc, curr) => acc + curr.irrigatedAmount, 0));
  }
}
