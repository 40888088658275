import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Chart, registerables } from 'chart.js';
import {
  IAgroMonitoringSatelliteImageDataViewModel,
  IAgroMonitoringSoilDataViewModel,
  IAgroMonitoringWeatherDataViewModel,
  IDateRangeSelectorForm,
  ISatelliteImageDataPointViewModel,
} from 'src/app/pages/dashboard/models/agromonitoring-data.model';
import { AgroMonitoringDataService } from 'src/app/pages/dashboard/services/agromonitoring-data.service';

@Component({
  selector: 'dfarm-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
})
export class ReportComponent implements OnInit {
  @Output() selectSatelliteImagesOutput = new EventEmitter<string>();

  maxDate = new Date();
  selectedDataPoint: ISatelliteImageDataPointViewModel;
  selectedDatetime: Date;
  selectedImageType: string;

  TRUE_COLOR = 'TRUE_COLOR';
  NDVI = 'NDVI';

  formControls: Record<keyof IDateRangeSelectorForm, FormControl> = {
    startDate: new FormControl(''),
    endDate: new FormControl(''),
  };
  dateRangeSelectorForm = new FormGroup(this.formControls);

  PLAY = 'Lejátszás';
  STOP = 'Stop';

  dateLabel: string;
  sliderMin: number = 0;
  sliderMax: number = 0;
  sliderValue: number = 0;
  playButtonOn: boolean = false;
  sliderIntervalId;

  @ViewChild('airTemperatureCanvas', { static: true })
  private airTemperatureCanvas: ElementRef;
  airTemperatureChart: any;

  @ViewChild('cumulativeAverageAirTemperatureCanvas', { static: true })
  private cumulativeAverageAirTemperatureCanvas: ElementRef;
  cumulativeAverageAirTemperatureChart: any;

  @ViewChild('soilTemperatureT0Canvas', { static: true })
  private soilTemperatureT0Canvas: ElementRef;
  soilTemperatureT0Chart: any;

  @ViewChild('cumulativeAverageSoilTemperatureT0Canvas', { static: true })
  private cumulativeAverageSoilTemperatureT0Canvas: ElementRef;
  cumulativeAverageSoilTemperatureT0Chart: any;

  @ViewChild('soilTemperatureT10Canvas', { static: true })
  private soilTemperatureT10Canvas: ElementRef;
  soilTemperatureT10Chart: any;

  @ViewChild('cumulativeAverageSoilTemperatureT10Canvas', { static: true })
  private cumulativeAverageSoilTemperatureT10Canvas: ElementRef;
  cumulativeAverageSoilTemperatureT10Chart: any;

  @ViewChild('precipitationCanvas', { static: true })
  private precipitationCanvas: ElementRef;
  precipitationChart: any;

  @ViewChild('cumulativePrecipitationCanvas', { static: true })
  private cumulativePrecipitationCanvas: ElementRef;
  cumulativePrecipitationChart: any;

  @ViewChild('humidityCanvas', { static: true })
  private humidityCanvas: ElementRef;
  humidityChart: any;

  @Input() landId;
  weatherData: IAgroMonitoringWeatherDataViewModel;
  soilData: IAgroMonitoringSoilDataViewModel;
  satelliteImageData: IAgroMonitoringSatelliteImageDataViewModel;
  availabelSatelliteImageData: IAgroMonitoringSatelliteImageDataViewModel;

  constructor(private agroMonitoringDataService: AgroMonitoringDataService) {}

  ngOnInit(): void {
    Chart.register(...registerables);

    this.airTemperatureChart = new Chart(this.airTemperatureCanvas.nativeElement, {
      type: 'line',
      data: {
        datasets: [],
      },
      options: {
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          x: {
            offset: true,
            ticks: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
            title: {
              display: true,
              text: 'Dátum',
              color: 'rgba(255, 255, 255, 0.75)',
            },
          },
          y: {
            min: -20,
            max: 50,
            ticks: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
            title: {
              display: true,
              text: 'Hőmérséklet (°C)',
              color: 'rgba(255, 255, 255, 0.75)',
            },
            grid: {
              drawBorder: false,
              color: function (context) {
                return 'black';
              },
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: 'Levegő hőmérséklet',
            color: 'white',
            font: {
              size: 26,
            },
            padding: {
              bottom: 10,
            },
          },
          legend: {
            labels: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
          },
        },
        layout: {
          padding: 20,
        },
      },
    });

    this.cumulativeAverageAirTemperatureChart = new Chart(this.cumulativeAverageAirTemperatureCanvas.nativeElement, {
      type: 'line',
      data: {
        datasets: [],
      },
      options: {
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          x: {
            offset: true,
            ticks: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
            title: {
              display: true,
              text: 'Dátum',
              color: 'rgba(255, 255, 255, 0.75)',
            },
          },
          y: {
            min: 0,
            ticks: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
            title: {
              display: true,
              text: 'Hőmérséklet (°C)',
              color: 'rgba(255, 255, 255, 0.75)',
            },
            grid: {
              drawBorder: false,
              color: function (context) {
                return 'black';
              },
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: 'Kumulált levegő hőmérséklet',
            color: 'white',
            font: {
              size: 26,
            },
            padding: {
              bottom: 10,
            },
          },
          legend: {
            labels: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
          },
        },
        layout: {
          padding: 20,
        },
      },
    });

    this.soilTemperatureT0Chart = new Chart(this.soilTemperatureT0Canvas.nativeElement, {
      type: 'line',
      data: {
        datasets: [],
      },
      options: {
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          x: {
            offset: true,
            ticks: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
            title: {
              display: true,
              text: 'Dátum',
              color: 'rgba(255, 255, 255, 0.75)',
            },
          },
          y: {
            min: -20,
            max: 50,
            ticks: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
            title: {
              display: true,
              text: 'Hőmérséklet (°C)',
              color: 'rgba(255, 255, 255, 0.75)',
            },
            grid: {
              drawBorder: false,
              color: function (context) {
                return 'black';
              },
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: 'Talajhőmérséklet (Felszín)',
            color: 'white',
            font: {
              size: 26,
            },
            padding: {
              bottom: 10,
            },
          },
          legend: {
            labels: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
          },
        },
        layout: {
          padding: 20,
        },
      },
    });

    this.cumulativeAverageSoilTemperatureT0Chart = new Chart(
      this.cumulativeAverageSoilTemperatureT0Canvas.nativeElement,
      {
        type: 'line',
        data: {
          datasets: [],
        },
        options: {
          interaction: {
            intersect: false,
            mode: 'index',
          },
          scales: {
            x: {
              offset: true,
              ticks: {
                color: 'rgba(255, 255, 255, 0.75)',
              },
              title: {
                display: true,
                text: 'Dátum',
                color: 'rgba(255, 255, 255, 0.75)',
              },
            },
            y: {
              min: 0,
              ticks: {
                color: 'rgba(255, 255, 255, 0.75)',
              },
              title: {
                display: true,
                text: 'Hőmérséklet (°C)',
                color: 'rgba(255, 255, 255, 0.75)',
              },
              grid: {
                drawBorder: false,
                color: function (context) {
                  return 'black';
                },
              },
            },
          },
          plugins: {
            title: {
              display: true,
              text: 'Kumulált talajhőmérséklet',
              color: 'white',
              font: {
                size: 26,
              },
              padding: {
                bottom: 10,
              },
            },
            legend: {
              labels: {
                color: 'rgba(255, 255, 255, 0.75)',
              },
            },
          },
          layout: {
            padding: 20,
          },
        },
      },
    );

    this.soilTemperatureT10Chart = new Chart(this.soilTemperatureT10Canvas.nativeElement, {
      type: 'line',
      data: {
        datasets: [],
      },
      options: {
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          x: {
            offset: true,
            ticks: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
            title: {
              display: true,
              text: 'Dátum',
              color: 'rgba(255, 255, 255, 0.75)',
            },
          },
          y: {
            min: -20,
            max: 50,
            ticks: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
            title: {
              display: true,
              text: 'Hőmérséklet (°C)',
              color: 'rgba(255, 255, 255, 0.75)',
            },
            grid: {
              drawBorder: false,
              color: function (context) {
                return 'black';
              },
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: 'Talajhőmérséklet (10 cm)',
            color: 'white',
            font: {
              size: 26,
            },
            padding: {
              bottom: 10,
            },
          },
          legend: {
            labels: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
          },
        },
        layout: {
          padding: 20,
        },
      },
    });

    this.cumulativeAverageSoilTemperatureT10Chart = new Chart(
      this.cumulativeAverageSoilTemperatureT10Canvas.nativeElement,
      {
        type: 'line',
        data: {
          datasets: [],
        },
        options: {
          interaction: {
            intersect: false,
            mode: 'index',
          },
          scales: {
            x: {
              offset: true,
              ticks: {
                color: 'rgba(255, 255, 255, 0.75)',
              },
              title: {
                display: true,
                text: 'Dátum',
                color: 'rgba(255, 255, 255, 0.75)',
              },
            },
            y: {
              min: 0,
              ticks: {
                color: 'rgba(255, 255, 255, 0.75)',
              },
              title: {
                display: true,
                text: 'Hőmérséklet (°C)',
                color: 'rgba(255, 255, 255, 0.75)',
              },
              grid: {
                drawBorder: false,
                color: function (context) {
                  return 'black';
                },
              },
            },
          },
          plugins: {
            title: {
              display: true,
              text: 'Kumulált talajhőmérséklet (10 cm)',
              color: 'white',
              font: {
                size: 26,
              },
              padding: {
                bottom: 10,
              },
            },
            legend: {
              labels: {
                color: 'rgba(255, 255, 255, 0.75)',
              },
            },
          },
          layout: {
            padding: 20,
          },
        },
      },
    );

    this.precipitationChart = new Chart(this.precipitationCanvas.nativeElement, {
      type: 'bar',
      data: {
        datasets: [],
      },
      options: {
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          x: {
            offset: true,
            ticks: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
            title: {
              display: true,
              text: 'Dátum',
              color: 'rgba(255, 255, 255, 0.75)',
            },
          },
          y: {
            min: 0,
            ticks: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
            title: {
              display: true,
              text: 'Mennyiség (mm)',
              color: 'rgba(255, 255, 255, 0.75)',
            },
            grid: {
              drawBorder: false,
              color: function (context) {
                return 'black';
              },
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: 'Csapadék',
            color: 'white',
            font: {
              size: 26,
            },
            padding: {
              bottom: 10,
            },
          },
          legend: {
            labels: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
          },
        },
        layout: {
          padding: 20,
        },
      },
    });

    this.cumulativePrecipitationChart = new Chart(this.cumulativePrecipitationCanvas.nativeElement, {
      type: 'line',
      data: {
        datasets: [],
      },
      options: {
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          x: {
            offset: true,
            ticks: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
            title: {
              display: true,
              text: 'Dátum',
              color: 'rgba(255, 255, 255, 0.75)',
            },
          },
          y: {
            min: 0,
            // max: 100,
            ticks: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
            title: {
              display: true,
              text: 'Mennyiség (mm)',
              color: 'rgba(255, 255, 255, 0.75)',
            },
            grid: {
              drawBorder: false,
              color: function (context) {
                return 'black';
              },
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: 'Kumulált csapadék',
            color: 'white',
            font: {
              size: 26,
            },
            padding: {
              bottom: 10,
            },
          },
          legend: {
            labels: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
          },
        },
        layout: {
          padding: 20,
        },
      },
    });

    this.humidityChart = new Chart(this.humidityCanvas.nativeElement, {
      type: 'line',
      data: {
        datasets: [],
      },
      options: {
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          x: {
            offset: true,
            ticks: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
            title: {
              display: true,
              text: 'Dátum',
              color: 'rgba(255, 255, 255, 0.75)',
            },
          },
          y: {
            min: 0,
            max: 100,
            ticks: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
            title: {
              display: true,
              text: 'Páratartalom (%)',
              color: 'rgba(255, 255, 255, 0.75)',
            },
            grid: {
              drawBorder: false,
              color: function (context) {
                return 'black';
              },
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: 'Páratartalom',
            color: 'white',
            font: {
              size: 26,
            },
            padding: {
              bottom: 10,
            },
          },
          legend: {
            labels: {
              color: 'rgba(255, 255, 255, 0.75)',
            },
          },
        },
        layout: {
          padding: 20,
        },
      },
    });
  }

  onSubmit(): void {
    if (this.dateRangeSelectorForm.valid && typeof this.landId !== 'undefined') {
      const intervalStart = new Date(this.formControls.startDate.value);
      const intervalEnd = new Date(this.formControls.endDate.value);

      this.agroMonitoringDataService
        .getAgroMonitoringWeatherData(this.landId, intervalStart, intervalEnd)
        .subscribe(res => {
          this.weatherData = res;

          this.updateAirTemperatureChart();
          this.updateCumulativeAverageAirTemperatureChart();
          this.updatePrecipitationChart();
          this.updateCumulativePrecipitationChart();
          this.updateHumidityChart();
        });

      this.agroMonitoringDataService
        .getAgroMonitoringSoilData(this.landId, intervalStart, intervalEnd)
        .subscribe(res => {
          this.soilData = res;
          this.updateSoilTemperatureT0Chart();
          this.updateCumulativeAverageSoilTemperatureT0Chart();
          this.updateSoilTemperatureT10Chart();
          this.updateCumulativeAverageSoilTemperatureT10Chart();
        });

      this.agroMonitoringDataService
        .getAgroMonitoringSatelliteImageData(this.landId, intervalStart, intervalEnd)
        .subscribe(res => {
          this.satelliteImageData = res;

          this.availabelSatelliteImageData = {
            dataPoints: this.satelliteImageData.dataPoints.filter(dataPoint => dataPoint.images.length > 0),
          };

          this.sliderMax = this.availabelSatelliteImageData.dataPoints.length - 1;
          this.dateLabel =
            this.availabelSatelliteImageData.dataPoints.length > 0
              ? this.availabelSatelliteImageData.dataPoints[0].datetime.toLocaleDateString()
              : '';
        });
    }
  }

  selectDate(value: number) {
    if (this.availabelSatelliteImageData === undefined) {
      return;
    }

    return this.availabelSatelliteImageData.dataPoints[value].datetime.toLocaleDateString();
  }

  playClicked() {
    if (this.availabelSatelliteImageData === undefined) {
      return;
    }

    this.playButtonOn = !this.playButtonOn;

    if (this.playButtonOn) {
      this.sliderIntervalId = setInterval(() => {
        this.nextImage();
        if (this.sliderValue === this.sliderMax) {
          clearInterval(this.sliderIntervalId);
          this.playButtonOn = false;
        }
      }, 2000);
    } else {
      clearInterval(this.sliderIntervalId);
      this.playButtonOn = false;
    }

    // this.sliderValue =
    // 	this.sliderValue + 1 ===
    // 	this.availabelSatelliteImageData.dataPoints.length
    // 		? this.availabelSatelliteImageData.dataPoints.length - 1
    // 		: this.sliderValue + 1;

    // const dataPoint =
    // 	this.availabelSatelliteImageData.dataPoints[this.sliderValue];
    // this.selectSatelliteImages(dataPoint);
  }

  previousImage() {
    if (this.availabelSatelliteImageData === undefined) {
      return;
    }

    this.sliderValue = this.sliderValue === this.sliderMin ? this.sliderMin : this.sliderValue - 1;

    const dataPoint = this.availabelSatelliteImageData.dataPoints[this.sliderValue];
    this.selectSatelliteImages(dataPoint);
  }

  nextImage() {
    if (this.availabelSatelliteImageData === undefined) {
      return;
    }

    this.sliderValue = this.sliderValue === this.sliderMax ? this.sliderMax : this.sliderValue + 1;

    const dataPoint = this.availabelSatelliteImageData.dataPoints[this.sliderValue];
    this.selectSatelliteImages(dataPoint);
  }

  selectSatelliteImages(dataPoint: ISatelliteImageDataPointViewModel) {
    this.selectedDataPoint = dataPoint;
    this.selectedDatetime = dataPoint.datetime;
    this.dateLabel = dataPoint.datetime.toLocaleDateString();

    if (this.selectedImageType) {
      this.selectedImageType === this.TRUE_COLOR
        ? this.selectSatelliteImagesOutput.emit(this.selectedDataPoint.images[0].imageTrueColor)
        : this.selectSatelliteImagesOutput.emit(this.selectedDataPoint.images[0].imageNDVI);
    }
  }

  selectImageType(imageType: string): void {
    if (this.availabelSatelliteImageData === undefined) {
      return;
    }

    this.selectedImageType = imageType;

    // if (this.selectedDataPoint) {
    // 	this.selectedImageType === this.TRUE_COLOR
    // 		? this.selectSatelliteImagesOutput.emit(
    // 				this.selectedDataPoint.images[0].imageTrueColor
    // 		  )
    // 		: this.selectSatelliteImagesOutput.emit(
    // 				this.selectedDataPoint.images[0].imageNDVI
    // 		  );
    // }

    const dataPoint = this.availabelSatelliteImageData.dataPoints[this.sliderValue];
    this.selectSatelliteImages(dataPoint);
  }

  updateAirTemperatureChart() {
    const newLabels = this.weatherData.dataPoints.map(dataPoint => dataPoint.datetime.toLocaleDateString());

    const newDatasets = [
      {
        label: 'Minimum',
        data: this.weatherData.dataPoints.map(dataPoint => dataPoint.temperature.minimum),
        borderColor: 'rgba(0, 128, 255, 0.8)',
        backgroundColor: 'rgba(0, 128, 255, 0.6)',
      },
      {
        label: 'Átlag',
        data: this.weatherData.dataPoints.map(dataPoint => dataPoint.temperature.average),
        borderColor: 'rgba(255, 128, 0, 0.8)',
        backgroundColor: 'rgba(255, 128, 0, 0.6)',
      },
      {
        label: 'Maximum',
        data: this.weatherData.dataPoints.map(dataPoint => dataPoint.temperature.maximum),
        borderColor: 'rgba(255, 0, 0, 0.8)',
        backgroundColor: 'rgba(255, 0, 0, 0.6)',
      },
    ];

    this.airTemperatureChart.data.labels = [];
    this.airTemperatureChart.data.datasets = [];

    newLabels.forEach(label => {
      this.airTemperatureChart.data.labels.push(label);
    });
    newDatasets.forEach(dataset => {
      this.airTemperatureChart.data.datasets.push(dataset);
    });

    this.airTemperatureChart.update();
  }

  updateCumulativeAverageAirTemperatureChart() {
    const newLabels = this.weatherData.dataPoints.map(dataPoint => dataPoint.datetime.toLocaleDateString());

    const findCumulativeSum = arr => {
      const creds = arr.reduce(
        (acc, val) => {
          let { sum, res } = acc;
          if (!isNaN(val)) {
            sum += val;
            res.push(sum);
          } else {
            res.push(NaN);
          }
          return { sum, res };
        },
        {
          sum: 0,
          res: [],
        },
      );
      return creds.res;
    };

    const newDatasets = [
      {
        label: 'Kumulált átlaghőmérsélet',
        data: findCumulativeSum(this.weatherData.dataPoints.map(dataPoint => dataPoint.temperature.average)),
        borderColor: 'rgba(255, 128, 0, 0.8)',
        backgroundColor: 'rgba(255, 128, 0, 0.6)',
        fill: true,
      },
    ];

    this.cumulativeAverageAirTemperatureChart.data.labels = [];
    this.cumulativeAverageAirTemperatureChart.data.datasets = [];

    newLabels.forEach(label => {
      this.cumulativeAverageAirTemperatureChart.data.labels.push(label);
    });
    newDatasets.forEach(dataset => {
      this.cumulativeAverageAirTemperatureChart.data.datasets.push(dataset);
    });

    this.cumulativeAverageAirTemperatureChart.update();
  }

  updateSoilTemperatureT0Chart() {
    const newLabels = this.soilData.dataPoints.map(dataPoint => dataPoint.datetime.toLocaleDateString());

    const newDatasets = [
      {
        label: 'Minimum',
        data: this.soilData.dataPoints.map(dataPoint => dataPoint.temperatureSurface.minimum),
        borderColor: 'rgba(0, 128, 255, 0.8)',
        backgroundColor: 'rgba(0, 128, 255, 0.6)',
      },
      {
        label: 'Átlag',
        data: this.soilData.dataPoints.map(dataPoint => dataPoint.temperatureSurface.average),
        borderColor: 'rgba(255, 128, 0, 0.8)',
        backgroundColor: 'rgba(255, 128, 0, 0.6)',
      },
      {
        label: 'Maximum',
        data: this.soilData.dataPoints.map(dataPoint => dataPoint.temperatureSurface.maximum),
        borderColor: 'rgba(255, 0, 0, 0.8)',
        backgroundColor: 'rgba(255, 0, 0, 0.6)',
      },
    ];

    this.soilTemperatureT0Chart.data.labels = [];
    this.soilTemperatureT0Chart.data.datasets = [];

    newLabels.forEach(label => {
      this.soilTemperatureT0Chart.data.labels.push(label);
    });
    newDatasets.forEach(dataset => {
      this.soilTemperatureT0Chart.data.datasets.push(dataset);
    });

    this.soilTemperatureT0Chart.update();
  }

  updateCumulativeAverageSoilTemperatureT0Chart() {
    const newLabels = this.soilData.dataPoints.map(dataPoint => dataPoint.datetime.toLocaleDateString());
    const findCumulativeSum = arr => {
      const creds = arr.reduce(
        (acc, val) => {
          let { sum, res } = acc;
          if (!isNaN(val)) {
            sum += val;
          }
          res.push(sum);
          return { sum, res };
        },
        {
          sum: 0,
          res: [],
        },
      );
      return creds.res;
    };
    const newDatasets = [
      {
        label: 'Kumulált átlaghőmérsélet',
        data: findCumulativeSum(this.soilData.dataPoints.map(dataPoint => dataPoint.temperatureSurface.average)),
        borderColor: 'rgba(255, 128, 0, 0.8)',
        backgroundColor: 'rgba(255, 128, 0, 0.6)',
        fill: true,
      },
    ];
    this.cumulativeAverageSoilTemperatureT0Chart.data.labels = [];
    this.cumulativeAverageSoilTemperatureT0Chart.data.datasets = [];
    newLabels.forEach(label => {
      this.cumulativeAverageSoilTemperatureT0Chart.data.labels.push(label);
    });
    newDatasets.forEach(dataset => {
      this.cumulativeAverageSoilTemperatureT0Chart.data.datasets.push(dataset);
    });
    this.cumulativeAverageSoilTemperatureT0Chart.update();
  }

  updateSoilTemperatureT10Chart() {
    const newLabels = this.soilData.dataPoints.map(dataPoint => dataPoint.datetime.toLocaleDateString());

    const newDatasets = [
      {
        label: 'Minimum',
        data: this.soilData.dataPoints.map(dataPoint => dataPoint.temperatureDepth10.minimum),
        borderColor: 'rgba(0, 128, 255, 0.8)',
        backgroundColor: 'rgba(0, 128, 255, 0.6)',
      },
      {
        label: 'Átlag',
        data: this.soilData.dataPoints.map(dataPoint => dataPoint.temperatureDepth10.average),
        borderColor: 'rgba(255, 128, 0, 0.8)',
        backgroundColor: 'rgba(255, 128, 0, 0.6)',
      },
      {
        label: 'Maximum',
        data: this.soilData.dataPoints.map(dataPoint => dataPoint.temperatureDepth10.maximum),
        borderColor: 'rgba(255, 0, 0, 0.8)',
        backgroundColor: 'rgba(255, 0, 0, 0.6)',
      },
    ];

    this.soilTemperatureT10Chart.data.labels = [];
    this.soilTemperatureT10Chart.data.datasets = [];

    newLabels.forEach(label => {
      this.soilTemperatureT10Chart.data.labels.push(label);
    });
    newDatasets.forEach(dataset => {
      this.soilTemperatureT10Chart.data.datasets.push(dataset);
    });

    this.soilTemperatureT10Chart.update();
  }

  updateCumulativeAverageSoilTemperatureT10Chart() {
    const newLabels = this.soilData.dataPoints.map(dataPoint => dataPoint.datetime.toLocaleDateString());
    const findCumulativeSum = arr => {
      const creds = arr.reduce(
        (acc, val) => {
          let { sum, res } = acc;
          if (!isNaN(val)) {
            sum += val;
          }
          res.push(sum);
          return { sum, res };
        },
        {
          sum: 0,
          res: [],
        },
      );
      return creds.res;
    };
    const newDatasets = [
      {
        label: 'Kumulált átlaghőmérsélet',
        data: findCumulativeSum(this.soilData.dataPoints.map(dataPoint => dataPoint.temperatureDepth10.average)),
        borderColor: 'rgba(255, 128, 0, 0.8)',
        backgroundColor: 'rgba(255, 128, 0, 0.6)',
        fill: true,
      },
    ];
    this.cumulativeAverageSoilTemperatureT10Chart.data.labels = [];
    this.cumulativeAverageSoilTemperatureT10Chart.data.datasets = [];
    newLabels.forEach(label => {
      this.cumulativeAverageSoilTemperatureT10Chart.data.labels.push(label);
    });
    newDatasets.forEach(dataset => {
      this.cumulativeAverageSoilTemperatureT10Chart.data.datasets.push(dataset);
    });
    this.cumulativeAverageSoilTemperatureT10Chart.update();
  }

  updatePrecipitationChart() {
    const newLabels = this.weatherData.dataPoints.map(dataPoint => dataPoint.datetime.toLocaleDateString());

    const newDatasets = [
      {
        label: 'Mennyiség',
        data: this.weatherData.dataPoints.map(dataPoint =>
          dataPoint.precipitation.sum ? dataPoint.precipitation.sum : null,
        ),
        borderColor: 'rgba(0, 128, 255, 0.8)',
        backgroundColor: 'rgba(0, 128, 255, 0.6)',
      },
    ];

    this.precipitationChart.data.labels = [];
    this.precipitationChart.data.datasets = [];

    newLabels.forEach(label => {
      this.precipitationChart.data.labels.push(label);
    });
    newDatasets.forEach(dataset => {
      this.precipitationChart.data.datasets.push(dataset);
    });

    this.precipitationChart.update();
  }

  updateCumulativePrecipitationChart() {
    const newLabels = this.weatherData.dataPoints.map(dataPoint => dataPoint.datetime.toLocaleDateString());

    const findCumulativeSum = arr => {
      const creds = arr.reduce(
        (acc, val) => {
          let { sum, res } = acc;
          if (!isNaN(val)) {
            sum += val;
            res.push(sum);
          } else {
            res.push(NaN);
          }
          return { sum, res };
        },
        {
          sum: 0,
          res: [],
        },
      );
      return creds.res;
    };

    const newDatasets = [
      {
        label: 'Kumulált mennyiség',
        data: findCumulativeSum(this.weatherData.dataPoints.map(dataPoint => dataPoint.precipitation.sum)),
        borderColor: 'rgba(0, 128, 255, 0.8)',
        backgroundColor: 'rgba(0, 128, 255, 0.6)',
        fill: true,
      },
    ];

    this.cumulativePrecipitationChart.data.labels = [];
    this.cumulativePrecipitationChart.data.datasets = [];

    newLabels.forEach(label => {
      this.cumulativePrecipitationChart.data.labels.push(label);
    });
    newDatasets.forEach(dataset => {
      this.cumulativePrecipitationChart.data.datasets.push(dataset);
    });

    this.cumulativePrecipitationChart.update();
  }

  updateHumidityChart() {
    const newLabels = this.weatherData.dataPoints.map(dataPoint => dataPoint.datetime.toLocaleDateString());
    const newDatasets = [
      {
        label: 'Minimum',
        data: this.weatherData.dataPoints.map(dataPoint => dataPoint.humidity.minimum),
        borderColor: 'rgba(0, 128, 255, 0.8)',
        backgroundColor: 'rgba(0, 128, 255, 0.6)',
      },
      {
        label: 'Átlag',
        data: this.weatherData.dataPoints.map(dataPoint => dataPoint.humidity.average),
        borderColor: 'rgba(255, 128, 0, 0.8)',
        backgroundColor: 'rgba(255, 128, 0, 0.6)',
      },
      {
        label: 'Maximum',
        data: this.weatherData.dataPoints.map(dataPoint => dataPoint.humidity.maximum),
        borderColor: 'rgba(255, 0, 0, 0.8)',
        backgroundColor: 'rgba(255, 0, 0, 0.6)',
      },
    ];

    this.humidityChart.data.labels = [];
    this.humidityChart.data.datasets = [];

    newLabels.forEach(label => {
      this.humidityChart.data.labels.push(label);
    });
    newDatasets.forEach(dataset => {
      this.humidityChart.data.datasets.push(dataset);
    });

    this.humidityChart.update();
  }
}
