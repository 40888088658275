<dfarm-panel
  *ngIf="!isLoading"
  class="dfarm-menu"
  [isLeftSide]="true"
  [isVisible]="panelIsOpen"
  [wrapperIsBlock]="true">
  <div class="list-wrapper p-2">
    <ul class="main-list">
      <li class="flex flex-col gap-1">
        <h2>{{ 'menu.fields-manager' | translate }}</h2>
        <h3
          class="flex items-center cursor-pointer hover:bg-light-green transition-all ease-in-out p-1 rounded-lg"
          (click)="showFieldInspectorPanel(); $event.stopPropagation()">
          <img alt="list" src="assets/menu-icons/list.svg" />
          {{ 'menu.field-list' | translate }}
        </h3>
        <h3
          class="flex items-center cursor-pointer hover:bg-light-green transition-all ease-in-out p-1 rounded-lg"
          (click)="showAddFieldPanel(); $event.stopPropagation()">
          <img alt="add-new-field" src="assets/menu-icons/add-new-field.svg" />
          {{ 'menu.add-field' | translate }}
        </h3>
      </li>

      <mat-divider></mat-divider>

      <li class="flex flex-col gap-1">
        <h2>{{ 'menu.reports' | translate }}</h2>
        <h3
          class="flex items-center justify-between cursor-pointer hover:bg-light-green transition-all ease-in-out p-1 rounded-lg"
          (click)="showDataViewerPanel(hasBasicPermission); $event.stopPropagation()">
          <div class="flex items-center">
            <img alt="weather-data" src="../../../../../assets/menu-icons/weather-data.svg" />
            {{ 'menu.weather-data' | translate }}
          </div>
          <mat-icon *ngIf="!hasBasicPermission">lock</mat-icon>
        </h3>
        <h3
          class="flex items-center justify-between cursor-pointer hover:bg-light-green transition-all ease-in-out p-1 rounded-lg"
          (click)="showSatelliteViewerPanel(hasBasicPermission); $event.stopPropagation()">
          <div class="flex items-center">
            <img alt="satellite" src="assets/menu-icons/satellite.svg" />
            {{ 'menu.satellite-data' | translate }}
          </div>
          <mat-icon *ngIf="!hasBasicPermission">lock</mat-icon>
        </h3>
        <h3
          class="flex items-center justify-between cursor-pointer hover:bg-light-green transition-all ease-in-out p-1 rounded-lg"
          (click)="showSensorViewerPanel(hasSensorPermission); $event.stopPropagation()">
          <div class="flex items-center">
            <img alt="windsock" src="assets/menu-icons/windsock.svg" />
            {{ 'menu.sensor-data' | translate }}
          </div>
          <mat-icon *ngIf="!hasSensorPermission">lock</mat-icon>
        </h3>
        <h3
          class="flex items-center justify-between cursor-pointer hover:bg-light-green transition-all ease-in-out p-1 rounded-lg"
          (click)="showSoilProbePanel(hasSensorPermission); $event.stopPropagation()">
          <div class="flex items-center">
            <img src="assets/menu-icons/soil-probe.png" />
            {{ 'menu.soil-probe' | translate }}
          </div>
          <mat-icon *ngIf="!hasSensorPermission">lock</mat-icon>
        </h3>
      </li>

      <mat-divider></mat-divider>

      <li class="flex flex-col gap-1">
        <h2>{{ 'menu.water-usage-reports' | translate }}</h2>
        <h3
          class="flex items-center justify-between cursor-pointer hover:bg-light-green transition-all ease-in-out p-1 rounded-lg"
          (click)="showWaterscopeItemListPanel(!isProduction); $event.stopPropagation()">
          <div class="flex items-center">
            <img alt="list" src="assets/menu-icons/list.svg" />
            {{ 'menu.water-devices-list' | translate }}
          </div>
          <mat-icon *ngIf="isProduction">lock</mat-icon>
        </h3>
        <h3
          class="flex items-center justify-between cursor-pointer hover:bg-light-green transition-all ease-in-out p-1 rounded-lg"
          (click)="showWaterscopeTablePanel(!isProduction); $event.stopPropagation()">
          <div class="flex items-center">
            <img alt="table-list" src="assets/menu-icons/table-list.svg" />
            {{ 'menu.water-table' | translate }}
          </div>
          <mat-icon *ngIf="isProduction">lock</mat-icon>
        </h3>
        <h3
          class="flex items-center justify-between cursor-pointer hover:bg-light-green transition-all ease-in-out p-1 rounded-lg"
          (click)="showWaterscopeChartPanel(!isProduction); $event.stopPropagation()">
          <div class="flex items-center">
            <img alt="stats" src="assets/menu-icons/stats.svg" />
            {{ 'menu.water-graph' | translate }}
          </div>
          <mat-icon *ngIf="isProduction">lock</mat-icon>
        </h3>
      </li>

      <!--      <mat-divider></mat-divider>-->

      <!--      <li class="main-list-item" customClassName="open-menu-list-item" dfarmExpandMenu>-->
      <!--        <div class="title-wrapper">-->
      <!--          <img class="arrow-icon" src="../../../../../assets/menu-icons/arrowIcon.svg"/>-->
      <!--          {{ 'menu.help' | translate }}-->
      <!--        </div>-->
      <!--        <ul class="sub-list">-->
      <!--          <li>-->
      <!--            <div (click)="navigateToDfarm()" class="subtitle-wrapper">-->
      <!--              <img src="../../../../../assets/menu-icons/contactIcon.svg"/>-->
      <!--              {{ 'menu.contact' | translate }}-->
      <!--            </div>-->
      <!--          </li>-->
      <!--        </ul>-->
      <!--      </li>-->
    </ul>
  </div>
</dfarm-panel>
