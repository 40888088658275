<form class="w-full text-sm mb-2 flex justify-evenly items-center" [formGroup]="dateRangeSelectorForm">
  <div>
    <label>
      <input
        type="date"
        class="bg-transparent w-28 text-center text-sm"
        [max]="formControls.endDate.value"
        formControlName="startDate"
        required />
    </label>
    -
    <label>
      <input
        type="date"
        class="bg-transparent w-28 text-center text-sm"
        [max]="maxDate"
        [min]="formControls.startDate.value"
        formControlName="endDate"
        required />
    </label>
  </div>
</form>
<div
  class="h-[90%] w-[90%] flex flex-col padding-2 desktop:w-full mobileVertical:overflow-auto desktop:overflow-auto tabletHorizontal:overflow-auto padding-2 rounded-lg">
  <div class="relative bg-dark-gray shadow-sunkenBoxShadow">
    <canvas id="preassureCanvas" #preassureCanvas></canvas>
  </div>
  <div class="relative bg-dark-gray shadow-sunkenBoxShadow">
    <canvas id="waterCanvas" #waterCanvas></canvas>
  </div>
  <div class="relative bg-dark-gray shadow-sunkenBoxShadow">
    <canvas id="petrolCanvas" #petrolCanvas></canvas>
  </div>
  <div class="relative bg-dark-gray shadow-sunkenBoxShadow">
    <canvas id="voltageCanvas" #voltageCanvas></canvas>
  </div>

  <!-- <div
  class="h-full w-full flex flex-col overflow-auto padding-2 rounded-[10px]"
> -->
  <!--  <div class="relative bg-dark-gray shadow-sunkenBoxShadow">-->
  <!--    <canvas #airTemperatureCanvas id="airTemperatureCanvas"></canvas>-->
  <!--  </div>-->
  <!--  &lt;!&ndash; <div class="relative bg-dark-gray shadow-sunkenBoxShadow">-->
  <!--    <canvas-->
  <!--      #cumulativeAverageAirTemperatureCanvas-->
  <!--      id="cumulativeAverageAirTemperatureCanvas"-->
  <!--    ></canvas>-->
  <!--  </div> &ndash;&gt;-->
  <!--  <div class="relative bg-dark-gray shadow-sunkenBoxShadow">-->
  <!--    <canvas #soilTemperatureT0Canvas id="soilTemperatureT0Canvas"></canvas>-->
  <!--  </div>-->
  <!--  &lt;!&ndash; <div class="relative bg-dark-gray shadow-sunkenBoxShadow">-->
  <!--    <canvas-->
  <!--      #cumulativeAverageSoilTemperatureT0Canvas-->
  <!--      id="cumulativeAverageSoilTemperatureT0Canvas"-->
  <!--    ></canvas>-->
  <!--  </div> &ndash;&gt;-->
  <!--  <div class="relative bg-dark-gray shadow-sunkenBoxShadow">-->
  <!--    <canvas #soilTemperatureT10Canvas id="soilTemperatureT10Canvas"></canvas>-->
  <!--  </div>-->
  <!--  &lt;!&ndash; <div class="relative bg-dark-gray shadow-sunkenBoxShadow">-->
  <!--    <canvas-->
  <!--      #cumulativeAverageSoilTemperatureT10Canvas-->
  <!--      id="cumulativeAverageSoilTemperatureT10Canvas"-->
  <!--    ></canvas>-->
  <!--  </div> &ndash;&gt;-->
  <!--  <div class="relative bg-dark-gray shadow-sunkenBoxShadow">-->
  <!--    <canvas #precipitationCanvas id="precipitationCanvas"></canvas>-->
  <!--  </div>-->
  <!--  <div class="relative bg-dark-gray shadow-sunkenBoxShadow">-->
  <!--    <canvas-->
  <!--      #cumulativePrecipitationCanvas-->
  <!--      id="cumulativePrecipitationCanvas"></canvas>-->
  <!--  </div>-->
  <!--  <div class="relative bg-dark-gray shadow-sunkenBoxShadow">-->
  <!--    <canvas #humidityCanvas id="humidityCanvas"></canvas>-->
  <!--  </div>-->
  <!--  <div class="relative bg-dark-gray shadow-sunkenBoxShadow">-->
  <!--    <canvas #uviCanvas id="uviCanvas"></canvas>-->
  <!--  </div>-->
</div>
