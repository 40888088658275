import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Chart, registerables } from 'chart.js';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';
import { CorePartialState } from 'src/app/core/state/core.reducer';
import {
  IAgroMonitoringSatelliteImageDataViewModel,
  IDateRangeSelectorForm,
  ISatelliteImageDataPointViewModel,
} from 'src/app/pages/dashboard/models/agromonitoring-data.model';
import { FieldDetailsViewModel } from 'src/app/pages/dashboard/models/field.model';
import { AgroMonitoringDataService } from 'src/app/pages/dashboard/services/agromonitoring-data.service';
import { DashboardPartialState } from 'src/app/pages/dashboard/state/dashboard.reducer';
import * as CoreQueries from '../../../../../../core/state/core.selectors';
import * as DashboardActions from '../../../../state/dashboard.actions';
import * as DashboardQueries from '../../../../state/dashboard.selectors';

@Component({
  selector: 'dfarm-satellite-data-details',
  templateUrl: './satellite-data-details.component.html',
  styleUrls: ['./satellite-data-details.component.scss'],
})
export class SatelliteDataDetailsComponent implements OnInit {
  subs: Subscription[] = [];

  satelliteViewerPanelIsOpen: boolean = false;

  formControls: Record<keyof IDateRangeSelectorForm, FormControl> = {
    startDate: new FormControl(''),
    endDate: new FormControl(''),
  };
  dateRangeSelectorForm = new FormGroup(this.formControls);

  selectedField: FieldDetailsViewModel;

  maxDate = new Date();

  @ViewChild('ndviCanvas', { static: true })
  private ndviCanvas: ElementRef;
  ndviChart: any;

  ndviSwitch = false;
  satelliteImageData: IAgroMonitoringSatelliteImageDataViewModel;
  satelliteImageDataSelector: IAgroMonitoringSatelliteImageDataViewModel;
  selectedSatelliteImageData: ISatelliteImageDataPointViewModel;
  selectedSatelliteImageDataIndex: number = null;

  constructor(
    private readonly dashboardStore: Store<DashboardPartialState>,
    private readonly coreStore: Store<CorePartialState>,
    private readonly agroMonitoringDataService: AgroMonitoringDataService,
    private readonly deviceDetectorService: DeviceDetectorService,
    private readonly translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    const today = new Date();
    const prev7days = new Date();
    prev7days.setDate(today.getDate() - 6);

    this.formControls.startDate.setValue(prev7days.toISOString().slice(0, 10), {
      emitEvent: false,
    });
    this.formControls.endDate.setValue(today.toISOString().slice(0, 10), {
      emitEvent: false,
    });
    this.subs.push(this.dateRangeSelectorForm.valueChanges.subscribe(() => this.getAgroMonitoringSatelliteData()));
    this.subs.push(
      this.dashboardStore.pipe(select(DashboardQueries.getSelectedField)).subscribe(selectedField => {
        if (selectedField === undefined) {
          return;
        }

        this.selectedField = selectedField;

        this.getAgroMonitoringSatelliteData();
      }),
      this.coreStore.pipe(select(CoreQueries.getSatelliteViewerPanelIsOpen)).subscribe(panelIsOpen => {
        this.satelliteViewerPanelIsOpen = panelIsOpen;
        if (panelIsOpen) {
          this.selectSatelliteImageData();
        } else {
          this.dashboardStore.dispatch(
            DashboardActions.setSelectedSatelliteImageURL({
              satelliteImageURL: null,
            }),
          );
        }
      }),
    );

    Chart.register(...registerables);

    this.ndviChart = new Chart(this.ndviCanvas.nativeElement, {
      type: 'bar',
      data: {
        datasets: [],
      },
      options: {
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          x: {
            offset: true,
            ticks: {
              display: !this.deviceDetectorService.isMobile(),
              color: '#002626',
            },
            title: {
              display: false,
              text: this.getTranslationTitle('date'),
              color: '#002626',
            },
          },
          y: {
            // min: 0,
            // max: 1,
            ticks: {
              color: '#002626',
            },
            title: {
              display: false,
              text: 'NDVI',
              color: '#002626',
            },
            grid: {
              drawBorder: false,
              color: function (context) {
                return 'black';
              },
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: this.getTranslationTitle('averageVegetationIndex'),
            color: '#002626',
            font: {
              size: 14,
            },
            padding: {
              bottom: 5,
            },
          },
          legend: {
            display: !this.deviceDetectorService.isMobile(),
            labels: {
              color: '#002626',
            },
          },
        },
        layout: {
          padding: 10,
        },
      },
    });
  }

  switchOff(value: boolean): void {
    this.ndviSwitch = value;
    this.selectSatelliteImageData();
  }

  private getAgroMonitoringSatelliteData(): void {
    if (this.dateRangeSelectorForm.valid && typeof this.selectedField !== 'undefined') {
      const intervalStart = new Date(this.formControls.startDate.value);
      const intervalEnd = new Date(this.formControls.endDate.value);

      this.agroMonitoringDataService
        .getAgroMonitoringSatelliteImageData(this.selectedField.id, intervalStart, intervalEnd)
        .subscribe(res => {
          this.satelliteImageData = res;
          this.satelliteImageDataSelector = {
            dataPoints: res.dataPoints.filter(dataPoint => dataPoint.images.length > 0),
          };

          if (this.satelliteImageData.dataPoints.length === 0) {
            this.selectedSatelliteImageDataIndex = null;
          } else {
            this.selectedSatelliteImageDataIndex = 0;
          }

          this.updateNDVIChart();

          if (this.satelliteViewerPanelIsOpen) {
            this.selectSatelliteImageData();
          }
        });
    }
  }

  updateNDVIChart() {
    const newLabels = this.satelliteImageData.dataPoints.map(dataPoint => dataPoint.datetime.toLocaleDateString());

    const newDatasets = [
      {
        label: this.getTranslationTitle('average'),
        // type: 'line',
        data: this.satelliteImageData.dataPoints.map(dataPoint =>
          dataPoint.images.length > 0 ? dataPoint.images[0].stats.ndvi.mean : NaN,
        ),
        borderColor: 'rgba(46, 139, 87, 0.8)',
        backgroundColor: 'rgba(46, 139, 87, 0.6)',
      },
    ];

    this.ndviChart.data.labels = [];
    this.ndviChart.data.datasets = [];

    newLabels.forEach(label => {
      this.ndviChart.data.labels.push(label);
    });
    newDatasets.forEach(dataset => {
      this.ndviChart.data.datasets.push(dataset);
    });

    this.ndviChart.update();
  }

  private selectSatelliteImageData(): void {
    if (this.satelliteImageDataSelector !== undefined) {
      this.selectedSatelliteImageData =
        this.satelliteImageDataSelector.dataPoints[this.selectedSatelliteImageDataIndex];

      let satelliteImageURL = null;
      if (this.selectedSatelliteImageData !== undefined) {
        satelliteImageURL = this.ndviSwitch
          ? this.selectedSatelliteImageData.images[0].imageNDVI
          : this.selectedSatelliteImageData.images[0].imageTrueColor;
      }

      this.dashboardStore.dispatch(
        DashboardActions.setSelectedSatelliteImageURL({
          satelliteImageURL,
        }),
      );
    }
  }

  selectNextSatelliteImageData(): void {
    if (this.satelliteImageDataSelector.dataPoints.length === 0) {
      return;
    }

    if (this.selectedSatelliteImageDataIndex + 1 < this.satelliteImageDataSelector.dataPoints.length) {
      this.selectedSatelliteImageDataIndex += 1;
      this.selectSatelliteImageData();
    }
  }

  selectPreviousSatelliteImageData(): void {
    if (this.satelliteImageDataSelector.dataPoints.length === 0) {
      return;
    }

    if (this.selectedSatelliteImageDataIndex - 1 >= 0) {
      this.selectedSatelliteImageDataIndex -= 1;
      this.selectSatelliteImageData();
    }
  }

  private getTranslationTitle = (translationPath: string): string =>
    this.translateService.instant(`dashboard-page.satellite-viewer-panel.${translationPath}`);
}
