<!-- TODO: Spinnert berakni -->
<div *ngIf="isLoading" class="activate-profile-wrapper p-6">
  <h2 class="!m-0">Töltés...</h2>
</div>
<div *ngIf="!isLoading" class="activate-profile-wrapper p-6">
  <div class="icon">
    <img alt="Congratulation" src="../../../../../assets/utility-icons/champagne.svg" />
  </div>
  <h2>{{ 'activate-profile-page.congratulations' | translate }}</h2>
  <dfarm-button [darkGreenButton]="true" (click)="navigateToLoginPage()">
    {{ 'activate-profile-page.login' | translate }}
  </dfarm-button>
</div>
