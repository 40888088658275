import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartNameEnum } from '../../../../models/sensor-data.model';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  ChartResolutionMap,
  ChartSelectedResolutionMap,
  Location,
  SensorDataResolutionEnum,
  SoilMoistureViewModel,
  SoilPotentialViewModel,
  SoilTemperatureViewModel,
} from '../../../../models/sensor-data.model-v2';
import { Chart, registerables } from 'chart.js';
import { select, Store } from '@ngrx/store';
import { CorePartialState } from '../../../../../../core/state/core.reducer';
import { DashboardPartialState } from '../../../../state/dashboard.reducer';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SensorDataServiceV2 } from '../../../../services/sensor-data.service-v2';
import { IDateRangeSelectorForm } from '../../../../models/agromonitoring-data.model';
import { forkJoin, Subscription } from 'rxjs';
import { DeviceTypeEnum } from '../../../../../../shared/enums/device-type.enum';
import * as CoreQueries from '../../../../../../core/state/core.selectors';
import * as DashboardQueries from '../../../../state/dashboard.selectors';
import { ResolutionButtonGroupComponent } from '../../../sensor-viewer/components/resolution-button-group/resolution-button-group.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ButtonComponent } from '../../../../../../shared/components/button/button.component';

@Component({
  selector: 'dfarm-soil-probe-viewer',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    ResolutionButtonGroupComponent,
    MatDatepickerModule,
    MatFormFieldModule,
    ButtonComponent,
  ],
  templateUrl: './soil-probe-viewer.component.html',
  styleUrl: './soil-probe-viewer.component.scss',
})
export class SoilProbeViewerComponent implements OnInit, OnChanges {
  @Input() intervalStart: Date;
  @Input() intervalEnd: Date;
  @Input() maxDate: Date;
  @Input() hasDeviceLocation = false;
  @Output() intervalStartChange = new EventEmitter<Date>();
  @Output() intervalEndChange = new EventEmitter<Date>();

  @ViewChild('soilTemperatureCanvas', { static: true })
  private soilTemperatureCanvas: ElementRef;
  soilTemperatureChart: any;

  @ViewChild('soilMoistureCanvas', { static: true })
  private soilMoistureCanvas: ElementRef;
  soilMoistureChart: any;

  @ViewChild('soilPotentialCanvas', { static: true })
  private soilPotentialCanvas: ElementRef;
  soilPotentialChart: any;

  soilTemperatureData: SoilTemperatureViewModel;
  soilMoistureData: SoilMoistureViewModel;
  soilPotentialData: SoilPotentialViewModel;

  formControls: Record<keyof IDateRangeSelectorForm, FormControl> = {
    startDate: new FormControl(new Date()),
    endDate: new FormControl(new Date()),
  };
  dateRangeSelectorForm = new FormGroup(this.formControls);

  selectedDeviceLocation: Location & { deviceType: DeviceTypeEnum } = {
    id: undefined,
    coordinate: undefined,
    deviceType: undefined,
  };
  chartResolutionMap: ChartResolutionMap[] = [];
  chartSelectedResolutionMap: ChartSelectedResolutionMap[] = [];
  protected readonly chartNameEnum = ChartNameEnum;

  subs: Subscription[] = [];
  soilProbeViewerPanelIsOpen = false;

  constructor(
    private readonly coreStore: Store<CorePartialState>,
    private readonly dashboardStore: Store<DashboardPartialState>,
    private readonly deviceDetectorService: DeviceDetectorService,
    private readonly sensorDataService: SensorDataServiceV2,
    private readonly translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.chartResolutionMap = this.sensorDataService.getChartResolutionMap();
    this.chartSelectedResolutionMap = this.sensorDataService.getChartSelectedResolutionMap();
    this.formControls.startDate.patchValue(this.intervalStart);
    this.formControls.endDate.patchValue(this.intervalEnd);

    this.subs.push(
      this.dateRangeSelectorForm.valueChanges.subscribe(value => {
        if (!value.startDate || !value.endDate) {
          return;
        }

        // this.intervalStartChange.emit(this.intervalStart);

        this.intervalEndChange.emit(this.intervalEnd);
      }),
    );

    this.subs.push(
      this.coreStore.pipe(select(CoreQueries.getSoilProbePanelIsOpen)).subscribe(panelIsOpen => {
        this.clearAllCharts();
        this.soilProbeViewerPanelIsOpen = panelIsOpen;
        if (panelIsOpen) {
          this.getData();
        }
      }),
    );

    this.subs.push(
      this.dashboardStore.pipe(select(DashboardQueries.getSelectedDeviceLocation)).subscribe(deviceLocation => {
        if (!deviceLocation || !this.soilProbeViewerPanelIsOpen) {
          return;
        }

        if (deviceLocation.id) {
          this.selectedDeviceLocation = deviceLocation;
        } else {
          this.selectedDeviceLocation = {
            id: undefined,
            coordinate: undefined,
            deviceType: undefined,
          };
        }

        if (this.soilProbeViewerPanelIsOpen) {
          this.getData();
        }
      }),
    );

    Chart.register(...registerables);
    this.soilTemperatureChart = new Chart(this.soilTemperatureCanvas.nativeElement, {
      type: 'line',
      data: {
        datasets: [],
      },
      options: {
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          x: {
            offset: true,
            ticks: {
              display: !this.deviceDetectorService.isMobile(),
              color: '#002626',
            },
            title: {
              display: false,
              text: this.translateService.instant('dashboard-page.sensor-viewer-panel.date'),
              color: '#002626',
            },
          },
          y: {
            ticks: {
              color: '#002626',
            },
            title: {
              display: false,
              text: this.translateService.instant('dashboard-page.sensor-viewer-panel.soil-temperature-with-degree'),
              color: '#002626',
            },
            grid: {
              drawBorder: false,
              color: function (context) {
                return 'black';
              },
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: this.translateService.instant('dashboard-page.sensor-viewer-panel.soil-temperature-with-degree'),
            color: '#002626',
            font: {
              size: 14,
            },
            padding: {
              bottom: 5,
            },
          },
          legend: {
            display: !this.deviceDetectorService.isMobile(),
            labels: {
              color: '#002626',
            },
          },
        },
        layout: {
          padding: 10,
        },
      },
    });

    this.soilMoistureChart = new Chart(this.soilMoistureCanvas.nativeElement, {
      type: 'line',
      data: {
        datasets: [],
      },
      options: {
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          x: {
            offset: true,
            ticks: {
              display: !this.deviceDetectorService.isMobile(),
              color: '#002626',
            },
            title: {
              display: false,
              text: this.translateService.instant('dashboard-page.sensor-viewer-panel.date'),
              color: '#002626',
            },
          },
          y: {
            ticks: {
              color: '#002626',
            },
            title: {
              display: false,
              text: this.translateService.instant('dashboard-page.sensor-viewer-panel.soil-moisture-with-percent'),
              color: '#002626',
            },
            grid: {
              drawBorder: false,
              color: function (context) {
                return 'black';
              },
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: this.translateService.instant('dashboard-page.sensor-viewer-panel.soil-moisture-with-percent'),
            color: '#002626',
            font: {
              size: 14,
            },
            padding: {
              bottom: 5,
            },
          },
          legend: {
            display: !this.deviceDetectorService.isMobile(),
            labels: {
              color: '#002626',
            },
          },
        },
        layout: {
          padding: 10,
        },
      },
    });

    this.soilPotentialChart = new Chart(this.soilPotentialCanvas.nativeElement, {
      type: 'line',
      data: {
        datasets: [],
      },
      options: {
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          x: {
            offset: true,
            ticks: {
              display: !this.deviceDetectorService.isMobile(),
              color: '#002626',
            },
            title: {
              display: false,
              text: this.translateService.instant('dashboard-page.sensor-viewer-panel.date'),
              color: '#002626',
            },
          },
          y: {
            ticks: {
              color: '#002626',
            },
            title: {
              display: false,
              text: this.translateService.instant('dashboard-page.sensor-viewer-panel.soil-potential-kpa'),
              color: '#002626',
            },
            grid: {
              drawBorder: false,
              color: function (context) {
                return 'black';
              },
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: this.translateService.instant('dashboard-page.sensor-viewer-panel.soil-potential-kpa'),
            color: '#002626',
            font: {
              size: 14,
            },
            padding: {
              bottom: 5,
            },
          },
          legend: {
            display: !this.deviceDetectorService.isMobile(),
            labels: {
              color: '#002626',
            },
          },
        },
        layout: {
          padding: 10,
        },
      },
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // for (const propName in changes) {
    //   const changedProp = changes[propName];
    //
    //   if (propName === 'intervalStart' && changedProp.currentValue !== undefined) {
    //     if (!this.intervalStartChangeEmitted) {
    //       this.formControls.startDate.setValue(this.intervalStart.toISOString().slice(0, 10), {
    //         emitEvent: false,
    //       });
    //     } else {
    //       this.intervalStartChangeEmitted = false;
    //     }
    //   } else if (propName === 'intervalEnd' && changedProp.currentValue !== undefined) {
    //     if (!this.intervalEndChangeEmitted) {
    //       this.formControls.endDate.setValue(this.intervalEnd.toISOString().slice(0, 10), {
    //         emitEvent: false,
    //       });
    //     } else {
    //       this.intervalEndChangeEmitted = false;
    //     }
    //   }
    // }
  }

  changeChartResolution(sensorDataResolutionEnum: SensorDataResolutionEnum, chartNameEnum: ChartNameEnum): void {
    const intervalStart = new Date(this.formControls.startDate.value);
    const intervalEnd = new Date(this.formControls.endDate.value);
    intervalStart.setHours(0, 0, 0, 0);
    intervalEnd.setHours(23, 59, 0, 0);

    switch (chartNameEnum.valueOf()) {
      case ChartNameEnum.soilTemperatureCanvas.valueOf():
        const soilTemperatureChartResolution = this.chartSelectedResolutionMap.find(x => x.chartName === chartNameEnum);
        soilTemperatureChartResolution.selectedResolution = sensorDataResolutionEnum;

        this.sensorDataService
          .getSoilTemperature(
            this.selectedDeviceLocation.id,
            intervalStart,
            intervalEnd,
            soilTemperatureChartResolution.selectedResolution,
          )
          .subscribe(soilTemperature => {
            this.soilTemperatureData = soilTemperature;
            this.clearSoilTemperatureChart();
            this.updateSoilTemperatureChart(soilTemperatureChartResolution.selectedResolution);
          });
        break;

      case ChartNameEnum.soilMoistureCanvas.valueOf():
        const soilMoistureChartResolution = this.chartSelectedResolutionMap.find(x => x.chartName === chartNameEnum);
        soilMoistureChartResolution.selectedResolution = sensorDataResolutionEnum;

        this.sensorDataService
          .getSoilMoisture(
            this.selectedDeviceLocation.id,
            intervalStart,
            intervalEnd,
            soilMoistureChartResolution.selectedResolution,
          )
          .subscribe(soilMoisture => {
            this.soilMoistureData = soilMoisture;
            this.clearSoilMoistureChart();
            this.updateSoilMoistureChart(soilMoistureChartResolution.selectedResolution);
          });
        break;

      case ChartNameEnum.soilPotentialCanvas.valueOf():
        const soilPotentialChartResolution = this.chartSelectedResolutionMap.find(x => x.chartName === chartNameEnum);
        soilPotentialChartResolution.selectedResolution = sensorDataResolutionEnum;

        this.sensorDataService
          .getSoilPotential(
            this.selectedDeviceLocation.id,
            intervalStart,
            intervalEnd,
            soilPotentialChartResolution.selectedResolution,
          )
          .subscribe(soilPotential => {
            this.soilPotentialData = soilPotential;
            this.clearSoilPotentialChart();
            this.updateSoilPotentialChart(soilPotentialChartResolution.selectedResolution);
          });
        break;

      default:
        break;
    }
  }

  getChartResolution(chartNameEnum: ChartNameEnum): ChartResolutionMap {
    return this.chartResolutionMap.find(x => x.chartName === chartNameEnum);
  }

  navigateToDfarmShop(): void {
    window.open('https://dmarketplace.eu', '_blank');
  }

  private getData(): void {
    if (
      this.dateRangeSelectorForm.valid &&
      this.selectedDeviceLocation !== undefined &&
      this.selectedDeviceLocation.coordinate !== undefined
    ) {
      const intervalStart = new Date(this.formControls.startDate.value);
      const intervalEnd = new Date(this.formControls.endDate.value);
      intervalStart.setHours(0, 0, 0, 0);
      intervalEnd.setHours(23, 59, 0, 0);

      const soilTemperatureChartResolution = this.chartSelectedResolutionMap.find(
        x => x.chartName === ChartNameEnum.soilTemperatureCanvas,
      ).selectedResolution;
      const soilMoistureChartResolution = this.chartSelectedResolutionMap.find(
        x => x.chartName === ChartNameEnum.soilMoistureCanvas,
      ).selectedResolution;
      const soilPotentialChartResolution = this.chartSelectedResolutionMap.find(
        x => x.chartName === ChartNameEnum.soilPotentialCanvas,
      ).selectedResolution;

      forkJoin([
        this.sensorDataService.getSoilTemperature(
          this.selectedDeviceLocation.id,
          intervalStart,
          intervalEnd,
          soilTemperatureChartResolution,
        ),
        this.sensorDataService.getSoilMoisture(
          this.selectedDeviceLocation.id,
          intervalStart,
          intervalEnd,
          soilMoistureChartResolution,
        ),
        this.sensorDataService.getSoilPotential(
          this.selectedDeviceLocation.id,
          intervalStart,
          intervalEnd,
          soilPotentialChartResolution,
        ),
      ]).subscribe(([soilTemperature, soilMoisture, soilPotential]) => {
        this.clearAllCharts();
        this.soilTemperatureData = soilTemperature;
        this.soilMoistureData = soilMoisture;
        this.soilPotentialData = soilPotential;

        this.updateSoilTemperatureChart(soilTemperatureChartResolution);
        this.updateSoilMoistureChart(soilMoistureChartResolution);
        this.updateSoilPotentialChart(soilPotentialChartResolution);
      });
    }
  }

  private clearSoilTemperatureChart(): void {
    if (this.soilTemperatureChart === undefined) {
      return;
    }

    this.soilTemperatureChart.data.labels = [];
    this.soilTemperatureChart.data.datasets = [];

    this.soilTemperatureChart.update();
  }

  private updateSoilTemperatureChart(sensorDataResolutionEnum: SensorDataResolutionEnum) {
    const newLabels = [];
    const newDatasets = [];

    if (sensorDataResolutionEnum === SensorDataResolutionEnum.hourly) {
      this.soilTemperatureData.hourly.time.forEach(time => {
        newLabels.push(time.toLocaleString());
      });

      const level1AverageDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.average-20'),
        data: [],
        borderColor: 'rgba(255, 128, 0, 0.6)',
        backgroundColor: 'rgba(255, 128, 0, 0.6)',
      };
      this.soilTemperatureData.hourly.level1Average.forEach(average => {
        level1AverageDataset.data.push(average);
      });
      newDatasets.push(level1AverageDataset);

      const level2AverageDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.average-40'),
        data: [],
        borderColor: 'rgba(255, 128, 0, 0.4)',
        backgroundColor: 'rgba(255, 128, 0, 0.4)',
      };
      this.soilTemperatureData.hourly.level2Average.forEach(average => {
        level2AverageDataset.data.push(average);
      });
      newDatasets.push(level2AverageDataset);

      const level3AverageDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.average-60'),
        data: [],
        borderColor: 'rgba(255, 128, 0, 0.2)',
        backgroundColor: 'rgba(255, 128, 0, 0.2)',
      };
      this.soilTemperatureData.hourly.level3Average.forEach(average => {
        level3AverageDataset.data.push(average);
      });
      newDatasets.push(level3AverageDataset);
    } else if (sensorDataResolutionEnum === SensorDataResolutionEnum.daily) {
      this.soilTemperatureData.daily.time.forEach(time => {
        newLabels.push(time.toLocaleDateString());
      });

      // const minimumDataset = {
      //   label: this.translateService.instant('dashboard-page.sensor-viewer-panel.minimum'),
      //   data: [],
      //   borderColor: 'rgba(0, 128, 255, 0.8)',
      //   backgroundColor: 'rgba(0, 128, 255, 0.6)',
      // };
      // this.soilTemperatureData.daily.level1Minimum.forEach((minimum) => {
      //   minimumDataset.data.push(minimum);
      // });
      // newDatasets.push(minimumDataset);

      const level1AverageDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.average-20'),
        data: [],
        borderColor: 'rgba(255, 128, 0, 0.6)',
        backgroundColor: 'rgba(255, 128, 0, 0.6)',
      };
      this.soilTemperatureData.daily.level1Average.forEach(average => {
        level1AverageDataset.data.push(average);
      });
      newDatasets.push(level1AverageDataset);

      const level2AverageDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.average-40'),
        data: [],
        borderColor: 'rgba(255, 128, 0, 0.4)',
        backgroundColor: 'rgba(255, 128, 0, 0.4)',
      };
      this.soilTemperatureData.daily.level2Average.forEach(average => {
        level2AverageDataset.data.push(average);
      });
      newDatasets.push(level2AverageDataset);

      const level3AverageDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.average-60'),
        data: [],
        borderColor: 'rgba(255, 128, 0, 0.2)',
        backgroundColor: 'rgba(255, 128, 0, 0.2)',
      };
      this.soilTemperatureData.daily.level3Average.forEach(average => {
        level3AverageDataset.data.push(average);
      });
      newDatasets.push(level3AverageDataset);

      // const maximumDataset = {
      //   label: this.translateService.instant('dashboard-page.sensor-viewer-panel.maximum'),
      //   data: [],
      //   borderColor: 'rgba(255, 0, 0, 0.8)',
      //   backgroundColor: 'rgba(255, 0, 0, 0.6)',
      // };
      // this.soilTemperatureData.daily.level1Maximum.forEach((maximum) => {
      //   maximumDataset.data.push(maximum);
      // });
      // newDatasets.push(maximumDataset);
    }

    newLabels.forEach(label => {
      this.soilTemperatureChart.data.labels.push(label);
    });
    newDatasets.forEach(dataset => {
      this.soilTemperatureChart.data.datasets.push(dataset);
    });

    this.soilTemperatureChart.update();
  }

  private clearSoilMoistureChart(): void {
    if (this.soilMoistureChart === undefined) {
      return;
    }

    this.soilMoistureChart.data.labels = [];
    this.soilMoistureChart.data.datasets = [];

    this.soilMoistureChart.update();
  }

  private updateSoilMoistureChart(sensorDataResolutionEnum: SensorDataResolutionEnum) {
    const newLabels = [];
    const newDatasets = [];

    if (sensorDataResolutionEnum === SensorDataResolutionEnum.raw) {
      this.soilMoistureData.raw.time.forEach(time => {
        newLabels.push(time.toLocaleString());
      });

      const level1ValuesDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.value-20'),
        data: [],
        borderColor: 'rgba(0, 128, 255, 0.6)',
        backgroundColor: 'rgba(0, 128, 255, 0.6)',
      };
      this.soilMoistureData.raw.level1Values.forEach(value => {
        level1ValuesDataset.data.push(value);
      });
      newDatasets.push(level1ValuesDataset);

      const level2ValuesDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.value-40'),
        data: [],
        borderColor: 'rgba(0, 128, 255, 0.4)',
        backgroundColor: 'rgba(0, 128, 255, 0.4)',
      };
      this.soilMoistureData.raw.level2Values.forEach(value => {
        level2ValuesDataset.data.push(value);
      });
      newDatasets.push(level2ValuesDataset);

      const level3ValuesDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.value-60'),
        data: [],
        borderColor: 'rgba(0, 128, 255, 0.2)',
        backgroundColor: 'rgba(0, 128, 255, 0.2)',
      };
      this.soilMoistureData.raw.level3Values.forEach(value => {
        level3ValuesDataset.data.push(value);
      });
      newDatasets.push(level3ValuesDataset);
    } else if (sensorDataResolutionEnum === SensorDataResolutionEnum.hourly) {
      this.soilMoistureData.hourly.time.forEach(time => {
        newLabels.push(time.toLocaleString());
      });

      const level1AverageDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.average-20'),
        data: [],
        borderColor: 'rgba(0, 128, 255, 0.6)',
        backgroundColor: 'rgba(0, 128, 255, 0.6)',
      };
      this.soilMoistureData.hourly.level1Average.forEach(average => {
        level1AverageDataset.data.push(average);
      });
      newDatasets.push(level1AverageDataset);

      const level2AverageDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.average-40'),
        data: [],
        borderColor: 'rgba(0, 128, 255, 0.4)',
        backgroundColor: 'rgba(0, 128, 255, 0.4)',
      };
      this.soilMoistureData.hourly.level2Average.forEach(average => {
        level2AverageDataset.data.push(average);
      });
      newDatasets.push(level2AverageDataset);

      const level3AverageDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.average-60'),
        data: [],
        borderColor: 'rgba(0, 128, 255, 0.2)',
        backgroundColor: 'rgba(0, 128, 255, 0.2)',
      };
      this.soilMoistureData.hourly.level3Average.forEach(average => {
        level3AverageDataset.data.push(average);
      });
      newDatasets.push(level3AverageDataset);
    } else if (sensorDataResolutionEnum === SensorDataResolutionEnum.daily) {
      this.soilMoistureData.daily.time.forEach(time => {
        newLabels.push(time.toLocaleDateString());
      });

      // const minimumDataset = {
      //   label: this.translateService.instant('dashboard-page.sensor-viewer-panel.minimum'),
      //   data: [],
      //   borderColor: 'rgba(0, 128, 255, 0.8)',
      //   backgroundColor: 'rgba(0, 128, 255, 0.6)',
      // };
      // this.soilMoistureData.daily.level1Minimum.forEach((minimum) => {
      //   minimumDataset.data.push(minimum);
      // });
      // newDatasets.push(minimumDataset);

      const level1AverageDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.average-20'),
        data: [],
        borderColor: 'rgba(0, 128, 255, 0.6)',
        backgroundColor: 'rgba(0, 128, 255, 0.6)',
      };
      this.soilMoistureData.daily.level1Average.forEach(average => {
        level1AverageDataset.data.push(average);
      });
      newDatasets.push(level1AverageDataset);

      const level2AverageDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.average-40'),
        data: [],
        borderColor: 'rgba(0, 128, 255, 0.4)',
        backgroundColor: 'rgba(0, 128, 255, 0.4)',
      };
      this.soilMoistureData.daily.level2Average.forEach(average => {
        level2AverageDataset.data.push(average);
      });
      newDatasets.push(level2AverageDataset);

      const level3AverageDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.average-60'),
        data: [],
        borderColor: 'rgba(0, 128, 255, 0.2)',
        backgroundColor: 'rgba(0, 128, 255, 0.2)',
      };
      this.soilMoistureData.daily.level3Average.forEach(average => {
        level3AverageDataset.data.push(average);
      });
      newDatasets.push(level3AverageDataset);

      // const maximumDataset = {
      //   label: this.translateService.instant('dashboard-page.sensor-viewer-panel.maximum'),
      //   data: [],
      //   borderColor: 'rgba(255, 0, 0, 0.8)',
      //   backgroundColor: 'rgba(255, 0, 0, 0.6)',
      // };
      // this.soilMoistureData.daily.level1Maximum.forEach((maximum) => {
      //   maximumDataset.data.push(maximum);
      // });
      // newDatasets.push(maximumDataset);
    }

    newLabels.forEach(label => {
      this.soilMoistureChart.data.labels.push(label);
    });
    newDatasets.forEach(dataset => {
      this.soilMoistureChart.data.datasets.push(dataset);
    });

    this.soilMoistureChart.update();
  }

  private clearSoilPotentialChart(): void {
    if (this.soilPotentialChart === undefined) {
      return;
    }

    this.soilPotentialChart.data.labels = [];
    this.soilPotentialChart.data.datasets = [];

    this.soilPotentialChart.update();
  }

  private updateSoilPotentialChart(sensorDataResolutionEnum: SensorDataResolutionEnum) {
    const newLabels = [];
    const newDatasets = [];

    if (sensorDataResolutionEnum === SensorDataResolutionEnum.hourly) {
      this.soilPotentialData.hourly.time.forEach(time => {
        newLabels.push(time.toLocaleString());
      });

      const averageDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.average'),
        data: [],
        borderColor: 'rgba(255, 128, 0, 0.8)',
        backgroundColor: 'rgba(255, 128, 0, 0.6)',
      };
      this.soilPotentialData.hourly.average.forEach(average => {
        averageDataset.data.push(average);
      });
      newDatasets.push(averageDataset);
    } else if (sensorDataResolutionEnum === SensorDataResolutionEnum.daily) {
      this.soilPotentialData.daily.time.forEach(time => {
        newLabels.push(time.toLocaleDateString());
      });

      const averageDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.average'),
        data: [],
        borderColor: 'rgba(255, 128, 0, 0.8)',
        backgroundColor: 'rgba(255, 128, 0, 0.6)',
      };
      this.soilPotentialData.daily.average.forEach(average => {
        averageDataset.data.push(average);
      });
      newDatasets.push(averageDataset);
    }

    newLabels.forEach(label => {
      this.soilPotentialChart.data.labels.push(label);
    });
    newDatasets.forEach(dataset => {
      this.soilPotentialChart.data.datasets.push(dataset);
    });

    this.soilPotentialChart.update();
  }

  private clearAllCharts(): void {
    this.clearSoilTemperatureChart();
    this.clearSoilMoistureChart();
    this.clearSoilPotentialChart();
  }
}
