<button
  class="text-center !cursor-pointer text-white border-transparent w-full"
  [ngClass]="{
    'dark-green-button': darkGreenButton,
    'light-green-button': lightGreenButton,
    'outline-button': outlineButton,
    'ghost-button': ghostButton,
    'disabled-button': disabled,
  }"
  [type]="type"
  [matTooltip]="tooltipText"
  [matTooltipDisabled]="tooltipIsDisabled">
  <ng-content></ng-content>
</button>

<!--'bg-gray-500 hover:bg-gray-700': disabled,-->
<!--'bg-gray-700 hover:bg-gray-500': disabled && darkGreenButton-->
