<button
  *ngFor="let resolution of resolutions; index as i"
  class="w-11 text-xs p-1 bg-dark-green !text-off-white"
  [ngClass]="{
    'rounded-l-md': i === 0,
    'rounded-r-md': i === resolutions.length - 1,
    'bg-light-green/50 text-off-white': selectedResolution !== resolution,
  }"
  (click)="changeResolution(resolution)">
  <span>
    {{ getTranslationTitle(resolution) }}
  </span>
</button>
