<dfarm-menu></dfarm-menu>
<!-- <dfarm-information-panel></dfarm-information-panel> -->
<dfarm-navbar></dfarm-navbar>
<dfarm-add-field
  [fields]="fields"
  [gpsPermissionDenied]="gpsPermissionDenied"
  [startGPSFieldDesignationDisabled]="startGPSFieldDesignationDisabled"></dfarm-add-field>
<dfarm-field-inspector [fields]="fields" (selectFieldOutput)="selectField($event)"></dfarm-field-inspector>
<!-- <dfarm-dfarm-services></dfarm-dfarm-services> -->
<dfarm-data-viewer
  [fields]="fields"
  (selectNextFieldOutput)="selectNextField()"
  (selectPreviousFieldOutput)="selectPreviousField()"></dfarm-data-viewer>
<dfarm-satellite-viewer
  [fields]="fields"
  (selectNextFieldOutput)="selectNextField()"
  (selectPreviousFieldOutput)="selectPreviousField()">
</dfarm-satellite-viewer>
<dfarm-sensor-viewer
  (selectNextFieldOutput)="selectNextField()"
  (selectPreviousFieldOutput)="selectPreviousField()"></dfarm-sensor-viewer>
<dfarm-soil-probe
  (selectNextFieldOutput)="selectNextField()"
  (selectPreviousFieldOutput)="selectPreviousField()"></dfarm-soil-probe>
<dfarm-waterscope-item-list></dfarm-waterscope-item-list>
<dfarm-waterscope-table></dfarm-waterscope-table>
<dfarm-waterscope-charts></dfarm-waterscope-charts>
<div id="map" class="match-parent"></div>
