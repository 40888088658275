<div class="flex flex-col items-center h-fit text-center text-white w-full desktop:p-2">
  <div class="flex-col tabletHorizontal:flex-row flex gap-2 w-full">
    <div class="flex flex-col gap-2 flex-1 bg-light-green rounded-lg shadow-sunkenBoxShadow">
      <label>{{ 'dashboard-page.data-viewer-panel.temperature' | translate }}</label>
      <div class="flex gap-2 items-center justify-center">
        <label class="text-off-white text-lg"
          >{{ 'dashboard-page.data-viewer-panel.min' | translate }} {{ kpiMinimumTemperature }} °C</label
        >
        <mat-divider class="h-4" vertical />
        <label class="text-off-white text-lg"
          >{{ 'dashboard-page.data-viewer-panel.max' | translate }} {{ kpiMaximumTemperature }} °C</label
        >
      </div>
    </div>
    <div class="flex flex-col gap-2 flex-1 bg-light-green rounded-lg shadow-sunkenBoxShadow">
      <label>{{ 'dashboard-page.data-viewer-panel.rain' | translate }}</label>
      <label class="text-off-white text-lg"
        >{{ kpiPrecipitation }} {{ 'dashboard-page.data-viewer-panel.mm' | translate }}</label
      >
    </div>
  </div>
  <form class="w-full text-sm my-2 flex justify-center items-center items-center" [formGroup]="dateRangeSelectorForm">
    <mat-form-field appearance="outline" class="w-full">
      <mat-date-range-input class="!cursor-pointer" [max]="maxDate" [rangePicker]="picker" (click)="picker.open()">
        <input class="!cursor-pointer" disabled formControlName="startDate" matStartDate placeholder="Start date" />
        <input class="!cursor-pointer" disabled formControlName="endDate" matEndDate placeholder="End date" />
      </mat-date-range-input>
      <mat-hint class="text-dark-green">YYYY. MM. DD. – YYYY. MM. DD.</mat-hint>
      <mat-datepicker-toggle [for]="picker" matIconSuffix />
      <mat-date-range-picker #picker />
    </mat-form-field>
  </form>
  <div
    class="h-full w-full flex flex-col padding-2 mobileVertical:overflow-auto desktop:overflow-auto tabletHorizontal:overflow-auto padding-2 rounded-lg">
    <!-- <div
    class="h-full w-full flex flex-col overflow-auto padding-2 rounded-[10px]"
  > -->
    <div class="relative bg-off-white shadow-sunkenBoxShadow">
      <canvas id="airTemperatureCanvas" #airTemperatureCanvas></canvas>
    </div>
    <!-- <div class="relative bg-off-white shadow-sunkenBoxShadow">
      <canvas
        #cumulativeAverageAirTemperatureCanvas
        id="cumulativeAverageAirTemperatureCanvas"
      ></canvas>
    </div> -->
    <div class="relative bg-off-white shadow-sunkenBoxShadow">
      <canvas id="soilTemperatureT0Canvas" #soilTemperatureT0Canvas></canvas>
    </div>
    <!-- <div class="relative bg-off-white shadow-sunkenBoxShadow">
      <canvas
        #cumulativeAverageSoilTemperatureT0Canvas
        id="cumulativeAverageSoilTemperatureT0Canvas"
      ></canvas>
    </div> -->
    <div class="relative bg-off-white shadow-sunkenBoxShadow">
      <canvas id="soilTemperatureT10Canvas" #soilTemperatureT10Canvas></canvas>
    </div>
    <!-- <div class="relative bg-off-white shadow-sunkenBoxShadow">
      <canvas
        #cumulativeAverageSoilTemperatureT10Canvas
        id="cumulativeAverageSoilTemperatureT10Canvas"
      ></canvas>
    </div> -->
    <div class="relative bg-off-white shadow-sunkenBoxShadow">
      <canvas id="precipitationCanvas" #precipitationCanvas></canvas>
    </div>
    <div class="relative bg-off-white shadow-sunkenBoxShadow">
      <canvas id="cumulativePrecipitationCanvas" #cumulativePrecipitationCanvas></canvas>
    </div>
    <div class="relative bg-off-white shadow-sunkenBoxShadow">
      <canvas id="humidityCanvas" #humidityCanvas></canvas>
    </div>
    <div class="relative bg-off-white shadow-sunkenBoxShadow">
      <canvas id="uviCanvas" #uviCanvas></canvas>
    </div>
  </div>
</div>
