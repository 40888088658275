<dfarm-panel
  *ngIf="!isLoading && hasPermission"
  [isVisible]="panelIsOpen"
  [isLeftSide]="false"
  [wrapperIsBlock]="false"
  [isClosable]="true"
  [isOverflowScrollablePanel]="true">
  <div class="title text-xl">
    {{ 'dashboard-page.data-viewer-panel.weather-data' | translate }}
  </div>
  <!-- Különböző diagramok amik mutatják az időjárási adatokat -->
  <div *ngIf="fields.length > 0" class="p-4 pt-0 h-[calc(100%-2*1rem-28px)] w-full">
    <div class="flex-col justify-between h-full">
      <div class="flex justify-between items-center mb-4">
        <dfarm-button (click)="selectPreviousField()">
          <img
            alt="left-arrow"
            class="h-7"
            src="../../../../../assets/quick-viewer-icons/navi-left.svg"
            height="38"
            width="15" />
        </dfarm-button>
        <div *ngIf="selectedField !== undefined" class="text-xl text-center text-dark-green">
          {{ selectedField.name }}
        </div>
        <dfarm-button (click)="selectNextField()">
          <img
            alt="right-arrow"
            class="h-7"
            src="../../../../../assets/quick-viewer-icons/navi-right.svg"
            height="38"
            width="15" />
        </dfarm-button>
      </div>
      <div class="w-full h-[95%] overflow-auto">
        <dfarm-field-data-details />
      </div>

      <!--      <mat-tab-group>-->
      <!--        <mat-tab [label]="getTranslationTitle('dashboard-page.data-viewer-panel.daily')">-->
      <!--          <div class="w-full h-full">-->
      <!--            <dfarm-field-data-overview></dfarm-field-data-overview>-->
      <!--          </div>-->
      <!--        </mat-tab>-->
      <!--        <mat-tab [label]="getTranslationTitle('dashboard-page.data-viewer-panel.details')">-->
      <!--          <div class="w-full h-full">-->
      <!--            <dfarm-field-data-details></dfarm-field-data-details>-->
      <!--          </div>-->
      <!--        </mat-tab>-->
      <!--      </mat-tab-group>-->
    </div>
  </div>

  <!-- Ez a rész akkor jelenik meg, ha egyetlen földje sincs a felhasznólnak -->
  <div *ngIf="fields.length === 0" class="field-list">
    <div class="flex flex-col justify-center items-center gap-1 h-full text-dark-green">
      <div>{{ 'dashboard-page.data-viewer-panel.no-fields' | translate }}</div>
      <div>
        {{ 'dashboard-page.data-viewer-panel.add-new-field' | translate }}
      </div>
      <dfarm-button [lightGreenButton]="true" (click)="showAddFieldPanel()">
        {{ 'dashboard-page.data-viewer-panel.add-new-field-button' | translate }}
      </dfarm-button>
    </div>
  </div>
</dfarm-panel>
