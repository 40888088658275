<div class="flex flex-col items-center h-full text-center text-dark-green">
  <form class="w-full text-sm mb-2 flex justify-evenly items-center" [formGroup]="dateRangeSelectorForm">
    <mat-form-field appearance="outline" class="w-full">
      <mat-date-range-input class="!cursor-pointer" [max]="maxDate" [rangePicker]="picker" (click)="picker.open()">
        <input class="!cursor-pointer" disabled formControlName="startDate" matStartDate placeholder="Start date" />
        <input class="!cursor-pointer" disabled formControlName="endDate" matEndDate placeholder="End date" />
      </mat-date-range-input>
      <mat-hint class="text-dark-green">YYYY. MM. DD. – YYYY. MM. DD.</mat-hint>
      <mat-datepicker-toggle [for]="picker" matIconSuffix />
      <mat-date-range-picker #picker />
    </mat-form-field>
  </form>
  <div class="h-full w-full flex flex-col overflow-auto padding-2 rounded-lg">
    <div class="relative bg-off-white shadow-sunkenBoxShadow rounded-lg">
      <canvas id="ndviCanvas" #ndviCanvas></canvas>
    </div>
  </div>
  <div class="w-full flex justify-evenly items-center mt-2 mb-4 text-dark-green">
    <div class="flex justify-around">
      <div class="text-base mr-2.5">
        {{ 'dashboard-page.satellite-viewer-panel.vegetation' | translate }}
      </div>
      <div>
        <button
          class="w-10 rounded-l-md text-xs p-1 text-dark-green"
          [ngClass]="{ 'bg-dark-green text-off-white': !ndviSwitch }"
          (click)="switchOff(false)">
          {{ 'dashboard-page.satellite-viewer-panel.off' | translate }}
        </button>
        <button
          class="w-10 rounded-r-md text-dark-green text-xs p-1"
          [ngClass]="{ 'bg-light-green text-off-white': ndviSwitch }"
          (click)="switchOff(true)">
          {{ 'dashboard-page.satellite-viewer-panel.on' | translate }}
        </button>
      </div>
    </div>
    <div *ngIf="selectedSatelliteImageData === undefined" class="flex justify-center items-center text-dark-green">
      {{ 'dashboard-page.satellite-viewer-panel.no-data' | translate }}
    </div>
    <div *ngIf="selectedSatelliteImageData !== undefined" class="flex justify-around">
      <button
        class="cursor-pointer mr-2.5"
        [ngClass]="{ 'opacity-0': selectedSatelliteImageDataIndex === 0 }"
        (click)="selectPreviousSatelliteImageData()">
        <img class="h-5" src="../../../../../assets/quick-viewer-icons/navi-left.svg" />
      </button>
      <div *ngIf="selectedSatelliteImageData !== undefined" class="text-base text-center text-dark-green">
        {{ selectedSatelliteImageData.datetime | date: 'yyyy.MM.dd.' }}
      </div>
      <button
        class="cursor-pointer ml-2.5"
        [ngClass]="{
          'opacity-0': selectedSatelliteImageDataIndex === satelliteImageDataSelector.dataPoints.length - 1,
        }"
        (click)="selectNextSatelliteImageData()">
        <img class="h-5" src="../../../../../assets/quick-viewer-icons/navi-right.svg" />
      </button>
    </div>
  </div>
</div>
