<dfarm-panel
  *ngIf="!isLoading"
  class="parent-panel"
  [isVisible]="panelIsOpen"
  [isLeftSide]="false"
  [activePanel]="activePanel"
  [isSlideable]="true"
  [isClosable]="true"
  [isMultiViewPanel]="true">
  <div class="child-panel" panel1>
    <div class="title text-xl">
      {{ 'dashboard-page.field-inspector-panel.fields-overview' | translate }}
    </div>
    <div class="p-4 pt-0 h-[calc(100%-2*1rem-28px)] w-full">
      <div class="input-wrapper rounded-lg">
        <label class="search-icon cursor-text" for="search">
          <img alt="search" class="h-9 bg-off-white" src="../../../../../assets/field-inspection-icons/search.svg" />
        </label>
        <input id="search" name="search" type="text" class="bg-off-white" [formControl]="searchField" />
      </div>
      <div
        *ngIf="fields.length > 0 && filteredFields.length > 0"
        class="field-list flex flex-col gap-2 bg-off-white p-1">
        <div
          *ngFor="let field of filteredFields"
          class="field-list-item p-2 hover:bg-light-green rounded-xl"
          (click)="selectField(field)">
          <div class="field-list-item-title text-lg">{{ field.name }}</div>
          <div class="icons flex gap-2">
            <dfarm-button (click)="editFieldName(field.id, field.name); $event.stopPropagation()">
              <img alt="edit" src="../../../../../assets/field-inspection-icons/edit.svg" />
            </dfarm-button>
            <dfarm-button (click)="deleteField(field.id); $event.stopPropagation()">
              <img alt="delete" src="../../../../../assets/field-inspection-icons/trash.svg" />
            </dfarm-button>
          </div>
        </div>
      </div>
      <div *ngIf="fields.length === 0" class="field-list bg-off-white">
        <div class="flex flex-col justify-center items-center gap-1 h-full text-dark-green">
          <p>
            {{ 'dashboard-page.field-inspector-panel.no-fields' | translate }}
          </p>
          <p>
            {{ 'dashboard-page.field-inspector-panel.please-add-new-field' | translate }}
          </p>
          <dfarm-button [lightGreenButton]="true" (click)="showAddFieldPanel()">
            {{ 'dashboard-page.field-inspector-panel.add-new-field' | translate }}
          </dfarm-button>
        </div>
      </div>
      <div *ngIf="filteredFields.length === 0" class="field-list bg-off-white">
        <div class="flex flex-col justify-center items-center gap-1 h-full text-dark-green">
          <div>
            {{ 'dashboard-page.field-inspector-panel.no-fields-found' | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="child-panel" panel2>
    <img
      alt="back"
      class="back-icon ml-4"
      (click)="backToList()"
      src="../../../../../assets/field-inspection-icons/arrow.svg" />
    <div class="title text-xl">{{ selectedField?.name }}</div>

    <div class="p-4 pt-0 h-[calc(100%-2*1rem-28px)] w-full">
      <dfarm-field-overview [selectedField]="selectedField"></dfarm-field-overview>
    </div>
  </div>
</dfarm-panel>
