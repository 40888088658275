<dfarm-panel
  *ngIf="!isLoading"
  class="parent-panel"
  [isVisible]="dfarmServicesIsOpen"
  [isLeftSide]="false"
  [isClosable]="true"
  [isWidePanel]="true">
  <div class="child-panel">
    <div class="title">Szolgáltatások áttekintése</div>

    <div class="field-list-wrapper">
      <div class="field-list">
        <!-- <div class="field-list-item" *ngFor="let field of fieldsWithServices">
            <div class="field-list-item-title">{{ field.fieldName }}</div>
        </div> -->
        <table class="mat-elevation-z8" [dataSource]="fieldsWithServices" mat-table>
          <!-- Field Name Column -->
          <ng-container matColumnDef="fieldName">
            <th *matHeaderCellDef mat-header-cell>Föld neve</th>
            <td *matCellDef="let element" mat-cell>{{ element.fieldName }}</td>
          </ng-container>

          <!-- Satelite Column -->
          <ng-container matColumnDef="isSatelite">
            <th *matHeaderCellDef mat-header-cell>Műhold</th>
            <td *matCellDef="let element" mat-cell>
              <img *ngIf="!element.isSatelite" alt="lock" src="../../../../../assets/utility-icons/lock.svg" />
              <img *ngIf="element.isSatelite" alt="checked" src="../../../../../assets/utility-icons/checked.svg" />
            </td>
          </ng-container>

          <!-- Sensor Column -->
          <ng-container matColumnDef="isSensor">
            <th *matHeaderCellDef mat-header-cell>Szenzor</th>
            <td *matCellDef="let element" mat-cell>
              <img *ngIf="!element.isSensor" alt="lock" src="../../../../../assets/utility-icons/lock.svg" />
              <img *ngIf="element.isSensor" alt="checked" src="../../../../../assets/utility-icons/checked.svg" />
            </td>
          </ng-container>

          <!-- Drone Column -->
          <ng-container matColumnDef="isDrone">
            <th *matHeaderCellDef mat-header-cell>Drón</th>
            <td *matCellDef="let element" mat-cell>
              <img *ngIf="!element.isDrone" alt="lock" src="../../../../../assets/utility-icons/lock.svg" />
              <img *ngIf="element.isDrone" alt="checked" src="../../../../../assets/utility-icons/checked.svg" />
            </td>
          </ng-container>

          <!-- Drone Column -->
          <ng-container matColumnDef="editing" width="20%">
            <th *matHeaderCellDef mat-header-cell></th>
            <td *matCellDef="let element" mat-cell>
              <button class="orange-button">Módosítás</button>
            </td>
          </ng-container>

          <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
          <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
        </table>
      </div>
    </div>
  </div>
</dfarm-panel>
