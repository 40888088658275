<div class="flex flex-col items-center h-[95%] text-center text-dark-green w-full p-2">
  <form class="w-full text-sm mb-2 flex justify-evenly items-center" [formGroup]="dateRangeSelectorForm">
    <mat-form-field appearance="outline" class="w-full">
      <mat-date-range-input class="!cursor-pointer" [max]="maxDate" [rangePicker]="picker" (click)="picker.open()">
        <input class="!cursor-pointer" disabled formControlName="startDate" matStartDate placeholder="Start date" />
        <input class="!cursor-pointer" disabled formControlName="endDate" matEndDate placeholder="End date" />
      </mat-date-range-input>
      <mat-hint class="text-dark-green">YYYY. MM. DD. – YYYY. MM. DD.</mat-hint>
      <mat-datepicker-toggle [for]="picker" matIconSuffix />
      <mat-date-range-picker #picker />
    </mat-form-field>
  </form>
  <div class="h-full w-full flex flex-col padding-2 padding-2 rounded-lg">
    <div
      *ngIf="!hasDeviceLocation"
      class="flex flex-col gap-2 h-[95%] w-full justify-center items-center text-dark-green">
      <p>{{ 'dashboard-page.sensor-viewer-panel.no-sensor-data' | translate }}</p>
      <dfarm-button [lightGreenButton]="true" (click)="navigateToDfarmShop()">Webshop</dfarm-button>
    </div>

    <!-- temperature -->
    <div
      class="relative bg-off-white shadow-sunkenBoxShadow invisible w-0"
      [ngClass]="{
        'visible-chart': hasDeviceLocation,
        '!h-0': !hasDeviceLocation,
      }">
      <canvas id="temperatureCanvas" #temperatureCanvas></canvas>
      <div *ngIf="getChartResolution(chartNameEnum.temperatureCanvas).resolutions.length > 1">
        <dfarm-resolution-button-group
          [resolutions]="getChartResolution(chartNameEnum.temperatureCanvas).resolutions"
          (resolutionChanged)="changeChartResolution($event, chartNameEnum.temperatureCanvas)"></dfarm-resolution-button-group>
      </div>
    </div>
    <!-- water amount -->
    <div
      class="relative bg-off-white shadow-sunkenBoxShadow invisible w-0"
      [ngClass]="{
        'visible-chart': hasDeviceLocation,
        '!h-0': !hasDeviceLocation,
      }">
      <canvas id="waterAmountCanvas" #waterAmountCanvas></canvas>
      <div *ngIf="getChartResolution(chartNameEnum.waterAmountCanvas).resolutions.length > 1">
        <dfarm-resolution-button-group
          [resolutions]="getChartResolution(chartNameEnum.waterAmountCanvas).resolutions"
          (resolutionChanged)="changeChartResolution($event, chartNameEnum.waterAmountCanvas)"></dfarm-resolution-button-group>
      </div>
    </div>
    <!-- cumulative water amount -->
    <div
      class="relative bg-off-white shadow-sunkenBoxShadow invisible w-0"
      [ngClass]="{
        'visible-chart': hasDeviceLocation,
        '!h-0': !hasDeviceLocation,
      }">
      <canvas id="cumulativeWaterAmountCanvas" #cumulativeWaterAmountCanvas></canvas>
      <div *ngIf="getChartResolution(chartNameEnum.cumulativeWaterAmountCanvas).resolutions.length > 1">
        <dfarm-resolution-button-group
          [resolutions]="getChartResolution(chartNameEnum.cumulativeWaterAmountCanvas).resolutions"
          (resolutionChanged)="changeChartResolution($event, chartNameEnum.cumulativeWaterAmountCanvas)"></dfarm-resolution-button-group>
      </div>
    </div>
    <!-- humidity -->
    <div
      class="relative bg-off-white shadow-sunkenBoxShadow invisible w-0"
      [ngClass]="{
        'visible-chart': hasDeviceLocation,
        '!h-0': !hasDeviceLocation,
      }">
      <canvas id="humidityCanvas" #humidityCanvas></canvas>
      <div *ngIf="getChartResolution(chartNameEnum.humidityCanvas).resolutions.length > 1">
        <dfarm-resolution-button-group
          [resolutions]="getChartResolution(chartNameEnum.humidityCanvas).resolutions"
          (resolutionChanged)="changeChartResolution($event, chartNameEnum.humidityCanvas)"></dfarm-resolution-button-group>
      </div>
    </div>
    <!-- ambient light -->
    <div
      class="relative bg-off-white shadow-sunkenBoxShadow invisible w-0"
      [ngClass]="{
        'visible-chart': hasDeviceLocation,
        '!h-0': !hasDeviceLocation,
      }">
      <canvas id="ambientLightCanvas" #ambientLightCanvas></canvas>
      <div *ngIf="getChartResolution(chartNameEnum.ambientLightCanvas).resolutions.length > 1">
        <dfarm-resolution-button-group
          [resolutions]="getChartResolution(chartNameEnum.ambientLightCanvas).resolutions"
          (resolutionChanged)="changeChartResolution($event, chartNameEnum.ambientLightCanvas)"></dfarm-resolution-button-group>
      </div>
    </div>
    <!-- Leaf Moisture -->
    <div class="relative bg-off-white shadow-sunkenBoxShadow">
      <canvas id="leafMoistureCanvas" #leafMoistureCanvas></canvas>
      <div *ngIf="getChartResolution(chartNameEnum.leafMoistureCanvas).resolutions.length > 1">
        <dfarm-resolution-button-group
          [resolutions]="getChartResolution(chartNameEnum.leafMoistureCanvas).resolutions"
          (resolutionChanged)="changeChartResolution($event, chartNameEnum.leafMoistureCanvas)" />
      </div>
    </div>
    <!-- Wind Speed -->
    <div
      class="relative bg-off-white shadow-sunkenBoxShadow invisible w-0"
      [ngClass]="{
        'visible-chart': hasDeviceLocation,
        '!h-0': !hasDeviceLocation,
      }">
      <canvas id="windSpeedCanvas" #windSpeedCanvas></canvas>
      <div *ngIf="getChartResolution(chartNameEnum.windSpeedCanvas).resolutions.length > 1">
        <dfarm-resolution-button-group
          [resolutions]="getChartResolution(chartNameEnum.windSpeedCanvas).resolutions"
          (resolutionChanged)="changeChartResolution($event, chartNameEnum.windSpeedCanvas)" />
      </div>
    </div>
  </div>
</div>
