<div class="dialog-wrapper">
  <div class="form-title">{{ 'dialogs.invoice.invoice-data' | translate }}</div>
  <form class="edit-profile-form" [formGroup]="editProfileFormGroup">
    <div class="input-wrapper">
      <label class="field-name-label">
        {{ 'dialogs.invoice.name' | translate }}
      </label>
      <input
        name="name"
        type="text"
        [ngClass]="{
          '!bg-[#505050] !text-[#A9A9A9]': formControls.name.disabled,
        }"
        [formControl]="formControls.name" />
    </div>
    <div class="input-wrapper">
      <label class="field-name-label">
        {{ 'dialogs.invoice.address' | translate }}
      </label>
      <input
        name="address"
        type="text"
        [ngClass]="{
          '!bg-[#505050] !text-[#A9A9A9]': formControls.address.disabled,
        }"
        [formControl]="formControls.address" />
    </div>
    <div class="input-wrapper">
      <label class="field-name-label">
        {{ 'dialogs.invoice.taxnumber' | translate }}
      </label>
      <input
        name="taxNumber"
        type="text"
        [ngClass]="{
          '!bg-[#505050] !text-[#A9A9A9]': formControls.taxNumber.disabled,
        }"
        [formControl]="formControls.taxNumber" />
    </div>
  </form>
  <div class="buttons">
    <ng-container *ngIf="!isEditing">
      <button class="dark-button" (click)="close()">
        {{ 'dialogs.invoice.close' | translate }}
      </button>
      <button class="light-button" (click)="editForm(true)">
        {{ 'dialogs.invoice.edit' | translate }}
      </button>
    </ng-container>
    <ng-container *ngIf="isEditing">
      <button class="dark-button" (click)="close()">
        {{ 'dialogs.invoice.cancel' | translate }}
      </button>
      <button class="light-button" (click)="save()">
        {{ 'dialogs.invoice.save' | translate }}
      </button>
    </ng-container>
  </div>
</div>
