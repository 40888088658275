<form class="login__container" [formGroup]="loginFormGroup">
  <div class="login__container__inner">
    <div class="logo">
      <img alt="logo" src="../../../../../assets/dfarm-logo-2.svg" />
    </div>
    <h1 class="login__title">{{ 'login-page.login' | translate }}</h1>
    <label class="login__label"> {{ 'login-page.email' | translate }} </label>
    <input name="email" type="email" [formControl]="formControls.username" placeholder="something@example.com" />
    <label class="login__label">
      {{ 'login-page.password' | translate }}
    </label>
    <input name="password" type="password" [formControl]="formControls.password" placeholder="*******" />
    <div class="flex flex-col gap-2">
      <dfarm-button type="submit" [outlineButton]="true" (click)="login()">
        {{ 'login-page.login' | translate }}
      </dfarm-button>
      <dfarm-button [ghostButton]="true" (click)="register()">
        {{ 'login-page.registration' | translate }}
      </dfarm-button>
      <dfarm-button [ghostButton]="true" (click)="forgotPassword()">
        {{ 'login-page.forgot-password' | translate }}
      </dfarm-button>
      <dfarm-language-selector></dfarm-language-selector>
    </div>
  </div>
</form>
