<dfarm-panel
  *ngIf="!isLoading && hasPermission"
  [isVisible]="panelIsOpen"
  [isLeftSide]="false"
  [wrapperIsBlock]="false"
  [isClosable]="true"
  [isOverflowScrollablePanel]="true">
  <div class="title text-dark-green text-xl">
    {{ 'dashboard-page.sensor-viewer-panel.sensor-data' | translate }}
  </div>
  <div class="p-4 pt-0 h-[calc(100%-2*1rem-28px)] w-full">
    <div class="flex-col justify-between h-full">
      <div class="flex justify-between items-center mb-4">
        <dfarm-button (click)="selectPreviousField()">
          <img
            alt="left-arrow"
            class="h-7"
            src="../../../../../assets/quick-viewer-icons/navi-left.svg"
            height="38"
            width="15" />
        </dfarm-button>
        <div *ngIf="selectedField !== undefined" class="text-xl text-center text-dark-green">
          {{ selectedField.name }}
        </div>
        <dfarm-button (click)="selectNextField()">
          <img
            alt="right-arrow"
            class="h-7"
            src="../../../../../assets/quick-viewer-icons/navi-right.svg"
            height="38"
            width="15" />
        </dfarm-button>
      </div>

      <div
        *ngIf="!hasDeviceLocation"
        class="w-full h-full overflow-auto text-center text-dark-green tabletHorizontal:max-h-[80%] desktop:max-h-[90%]">
        <dfarm-sensor-viewer-details
          [maxDate]="maxDate"
          [intervalStart]="intervalStart"
          [intervalEnd]="intervalEnd"
          [hasSensorDataLocation]="hasSensorDataLocation"
          (intervalStartChange)="intervalStartChanged($event)"
          (intervalEndChange)="intervalEndChanged($event)" />
      </div>
      <div
        *ngIf="hasDeviceLocation"
        class="w-full h-full overflow-auto text-center text-dark-green tabletHorizontal:max-h-[80%] desktop:max-h-[90%]">
        <dfarm-sensor-viewer-details-v2
          [maxDate]="maxDate"
          [intervalStart]="intervalStart"
          [intervalEnd]="intervalEnd"
          [hasDeviceLocation]="hasDeviceLocation"
          (intervalStartChange)="intervalStartChanged($event)"
          (intervalEndChange)="intervalEndChanged($event)" />
      </div>
    </div>
  </div>
</dfarm-panel>
