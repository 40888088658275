import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Chart, registerables } from 'chart.js';
import { DeviceDetectorService } from 'ngx-device-detector';
import { forkJoin, Subscription } from 'rxjs';
import { CorePartialState } from 'src/app/core/state/core.reducer';
import { IDateRangeSelectorForm } from 'src/app/pages/dashboard/models/agromonitoring-data.model';
import {
  AmbientLightViewModel,
  ChartResolutionMap,
  ChartSelectedResolutionMap,
  CumulativeWaterAmountViewModel,
  HumidityViewModel,
  LeafMoistureViewModel,
  Location,
  SensorDataResolutionEnum,
  TemperatureViewModel,
  WaterAmountViewModel,
  WindSpeedViewModel,
} from 'src/app/pages/dashboard/models/sensor-data.model-v2';
import { SensorDataServiceV2 } from 'src/app/pages/dashboard/services/sensor-data.service-v2';
import { DashboardPartialState } from 'src/app/pages/dashboard/state/dashboard.reducer';
import { DeviceTypeEnum } from 'src/app/shared/enums/device-type.enum';
import * as CoreQueries from '../../../../../../core/state/core.selectors';
import * as DashboardQueries from '../../../../state/dashboard.selectors';
import { ChartNameEnum } from '../../../../models/sensor-data.model';

@Component({
  selector: 'dfarm-sensor-viewer-details-v2',
  templateUrl: './sensor-viewer-details.component-v2.html',
  styleUrls: ['./sensor-viewer-details.component-v2.scss'],
})
export class SensorViewerDetailsComponentV2 implements OnInit, OnChanges {
  @ViewChild('temperatureCanvas', { static: true })
  private temperatureCanvas: ElementRef;
  temperatureChart: any;

  @ViewChild('waterAmountCanvas', { static: true })
  private waterAmountCanvas: ElementRef;
  waterAmountChart: any;

  @ViewChild('cumulativeWaterAmountCanvas', { static: true })
  private cumulativeWaterAmountCanvas: ElementRef;
  cumulativeWaterAmountChart: any;

  @ViewChild('humidityCanvas', { static: true })
  private humidityCanvas: ElementRef;
  humidityChart: any;

  @ViewChild('ambientLightCanvas', { static: true })
  private ambientLightCanvas: ElementRef;
  ambientLightChart: any;

  @ViewChild('leafMoistureCanvas', { static: true })
  private leafMoistureCanvas: ElementRef;
  leafMoistureChart: any;

  @ViewChild('windSpeedCanvas', { static: true })
  private windSpeedCanvas: ElementRef;
  windSpeedChart: any;

  subs: Subscription[] = [];
  sensorViewerPanelIsOpen = false;

  formControls: Record<keyof IDateRangeSelectorForm, FormControl> = {
    startDate: new FormControl(new Date()),
    endDate: new FormControl(new Date()),
  };
  dateRangeSelectorForm = new FormGroup(this.formControls);

  @Input() intervalStart: Date;
  @Input() intervalEnd: Date;
  @Input() maxDate: Date;
  @Output() intervalStartChange = new EventEmitter<Date>();
  @Output() intervalEndChange = new EventEmitter<Date>();

  @Input() hasDeviceLocation = false;

  temperatureData: TemperatureViewModel;
  waterAmountData: WaterAmountViewModel;
  cumulativeWaterAmountData: CumulativeWaterAmountViewModel;
  humidityData: HumidityViewModel;
  ambientLightData: AmbientLightViewModel;
  leafMoistureData: LeafMoistureViewModel;
  windSpeedData: WindSpeedViewModel;

  selectedDeviceLocation: Location & { deviceType: DeviceTypeEnum } = {
    id: undefined,
    coordinate: undefined,
    deviceType: undefined,
  };
  chartNameEnum = ChartNameEnum;
  chartResolutionMap: ChartResolutionMap[] = [];
  chartSelectedResolutionMap: ChartSelectedResolutionMap[] = [];

  constructor(
    private readonly coreStore: Store<CorePartialState>,
    private readonly dashboardStore: Store<DashboardPartialState>,
    private readonly deviceDetectorService: DeviceDetectorService,
    private readonly sensorDataService: SensorDataServiceV2,
    private readonly translateService: TranslateService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    // for (const propName in changes) {
    //   const changedProp = changes[propName];
    //
    //   if (propName === 'intervalStart' && changedProp.currentValue !== undefined) {
    //     if (!this.intervalStartChangeEmitted) {
    //       this.formControls.startDate.setValue(this.intervalStart.toISOString().slice(0, 10), {
    //         emitEvent: false,
    //       });
    //     } else {
    //       this.intervalStartChangeEmitted = false;
    //     }
    //   } else if (propName === 'intervalEnd' && changedProp.currentValue !== undefined) {
    //     if (!this.intervalEndChangeEmitted) {
    //       this.formControls.endDate.setValue(this.intervalEnd.toISOString().slice(0, 10), {
    //         emitEvent: false,
    //       });
    //     } else {
    //       this.intervalEndChangeEmitted = false;
    //     }
    //   }
    // }
  }

  ngOnInit(): void {
    this.chartResolutionMap = this.sensorDataService.getChartResolutionMap();
    this.chartSelectedResolutionMap = this.sensorDataService.getChartSelectedResolutionMap();
    this.formControls.startDate.patchValue(this.intervalStart);
    this.formControls.endDate.patchValue(this.intervalEnd);

    this.subs.push(
      this.dateRangeSelectorForm.valueChanges.subscribe(value => {
        if (!value.startDate || !value.endDate) {
          return;
        }

        this.intervalEndChange.emit(this.intervalEnd);
      }),
    );

    this.subs.push(
      this.coreStore.pipe(select(CoreQueries.getSensorViewerPanelIsOpen)).subscribe(panelIsOpen => {
        this.sensorViewerPanelIsOpen = panelIsOpen;
        this.clearAllCharts();
        if (panelIsOpen) {
          this.getData();
        }
      }),
    );

    this.subs.push(
      this.dashboardStore.pipe(select(DashboardQueries.getSelectedDeviceLocation)).subscribe(deviceLocation => {
        if (!deviceLocation || !this.sensorViewerPanelIsOpen) {
          return;
        }
        this.clearAllCharts();

        if (deviceLocation.id) {
          this.selectedDeviceLocation = deviceLocation;
        } else {
          this.selectedDeviceLocation = {
            id: undefined,
            coordinate: undefined,
            deviceType: undefined,
          };
        }

        if (this.sensorViewerPanelIsOpen) {
          this.getData();
        }
      }),
    );

    Chart.register(...registerables);

    this.temperatureChart = new Chart(this.temperatureCanvas.nativeElement, {
      type: 'line',
      data: {
        datasets: [],
      },
      options: {
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          x: {
            offset: true,
            ticks: {
              display: !this.deviceDetectorService.isMobile(),
              color: '#002626',
            },
            title: {
              display: false,
              text: this.translateService.instant('dashboard-page.sensor-viewer-panel.date'),
              color: '#002626',
            },
          },
          y: {
            ticks: {
              color: '#002626',
            },
            title: {
              display: false,
              text: this.translateService.instant('dashboard-page.sensor-viewer-panel.temperature-with-degree'),
              color: '#002626',
            },
            grid: {
              drawBorder: false,
              color: function (context) {
                return 'black';
              },
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: this.translateService.instant('dashboard-page.sensor-viewer-panel.air-temperature'),
            color: '#002626',
            font: {
              size: 14,
            },
            padding: {
              bottom: 5,
            },
          },
          legend: {
            display: !this.deviceDetectorService.isMobile(),
            labels: {
              color: '#002626',
            },
          },
        },
        layout: {
          padding: 10,
        },
      },
    });

    this.waterAmountChart = new Chart(this.waterAmountCanvas.nativeElement, {
      type: 'bar',
      data: {
        datasets: [],
      },
      options: {
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          x: {
            offset: true,
            ticks: {
              display: !this.deviceDetectorService.isMobile(),
              color: '#002626',
            },
            title: {
              display: false,
              text: this.translateService.instant('dashboard-page.sensor-viewer-panel.date'),
              color: '#002626',
            },
          },
          y: {
            min: 0,
            ticks: {
              color: '#002626',
            },
            title: {
              display: false,
              text: this.translateService.instant('dashboard-page.sensor-viewer-panel.amount'),
              color: '#002626',
            },
            grid: {
              drawBorder: false,
              color: function (context) {
                return 'black';
              },
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: this.translateService.instant('dashboard-page.sensor-viewer-panel.water-mm'),
            color: '#002626',
            font: {
              size: 14,
            },
            padding: {
              bottom: 5,
            },
          },
          legend: {
            display: !this.deviceDetectorService.isMobile(),
            labels: {
              color: '#002626',
            },
          },
        },
        layout: {
          padding: 10,
        },
      },
    });

    this.cumulativeWaterAmountChart = new Chart(this.cumulativeWaterAmountCanvas.nativeElement, {
      type: 'line',
      data: {
        datasets: [],
      },
      options: {
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          x: {
            offset: true,
            ticks: {
              display: !this.deviceDetectorService.isMobile(),
              color: '#002626',
            },
            title: {
              display: false,
              text: this.translateService.instant('dashboard-page.sensor-viewer-panel.date'),
              color: '#002626',
            },
          },
          y: {
            min: 0,
            ticks: {
              color: '#002626',
            },
            title: {
              display: false,
              text: this.translateService.instant('dashboard-page.sensor-viewer-panel.amount'),
              color: '#002626',
            },
            grid: {
              drawBorder: false,
              color: function (context) {
                return 'black';
              },
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: this.translateService.instant('dashboard-page.sensor-viewer-panel.cumulative-water-mm'),
            color: '#002626',
            font: {
              size: 14,
            },
            padding: {
              bottom: 5,
            },
          },
          legend: {
            display: !this.deviceDetectorService.isMobile(),
            labels: {
              color: '#002626',
            },
          },
        },
        layout: {
          padding: 10,
        },
      },
    });

    this.humidityChart = new Chart(this.humidityCanvas.nativeElement, {
      type: 'line',
      data: {
        datasets: [],
      },
      options: {
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          x: {
            offset: true,
            ticks: {
              display: !this.deviceDetectorService.isMobile(),
              color: '#002626',
            },
            title: {
              display: false,
              text: this.translateService.instant('dashboard-page.sensor-viewer-panel.date'),
              color: '#002626',
            },
          },
          y: {
            ticks: {
              color: '#002626',
            },
            title: {
              display: false,
              text: this.translateService.instant('dashboard-page.sensor-viewer-panel.humidity'),
              color: '#002626',
            },
            grid: {
              drawBorder: false,
              color: function (context) {
                return 'black';
              },
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: this.translateService.instant('dashboard-page.sensor-viewer-panel.humidity'),
            color: '#002626',
            font: {
              size: 14,
            },
            padding: {
              bottom: 5,
            },
          },
          legend: {
            display: !this.deviceDetectorService.isMobile(),
            labels: {
              color: '#002626',
            },
          },
        },
        layout: {
          padding: 10,
        },
      },
    });

    this.ambientLightChart = new Chart(this.ambientLightCanvas.nativeElement, {
      type: 'line',
      data: {
        datasets: [],
      },
      options: {
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          x: {
            offset: true,
            ticks: {
              display: !this.deviceDetectorService.isMobile(),
              color: '#002626',
            },
            title: {
              display: false,
              text: this.translateService.instant('dashboard-page.sensor-viewer-panel.date'),
              color: '#002626',
            },
          },
          y: {
            ticks: {
              color: '#002626',
            },
            title: {
              display: false,
              text: this.translateService.instant('dashboard-page.sensor-viewer-panel.ambient-light'),
              color: '#002626',
            },
            grid: {
              drawBorder: false,
              color: function (context) {
                return 'black';
              },
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: this.translateService.instant('dashboard-page.sensor-viewer-panel.ambient-light-lux'),
            color: '#002626',
            font: {
              size: 14,
            },
            padding: {
              bottom: 5,
            },
          },
          legend: {
            display: !this.deviceDetectorService.isMobile(),
            labels: {
              color: '#002626',
            },
          },
        },
        layout: {
          padding: 10,
        },
      },
    });

    this.leafMoistureChart = new Chart(this.leafMoistureCanvas.nativeElement, {
      type: 'line',
      data: {
        datasets: [],
      },
      options: {
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          x: {
            offset: true,
            ticks: {
              display: !this.deviceDetectorService.isMobile(),
              color: '#002626',
            },
            title: {
              display: false,
              text: this.translateService.instant('dashboard-page.sensor-viewer-panel.date'),
              color: '#002626',
            },
          },
          y: {
            ticks: {
              color: '#002626',
            },
            title: {
              display: false,
              text: this.translateService.instant('dashboard-page.sensor-viewer-panel.leaf-moisture-with-percent'),
              color: '#002626',
            },
            grid: {
              drawBorder: false,
              color: function (context) {
                return 'black';
              },
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: this.translateService.instant('dashboard-page.sensor-viewer-panel.leaf-moisture-with-percent'),
            color: '#002626',
            font: {
              size: 14,
            },
            padding: {
              bottom: 5,
            },
          },
          legend: {
            display: !this.deviceDetectorService.isMobile(),
            labels: {
              color: '#002626',
            },
          },
        },
        layout: {
          padding: 10,
        },
      },
    });

    this.windSpeedChart = new Chart(this.windSpeedCanvas.nativeElement, {
      type: 'line',
      data: {
        datasets: [],
      },
      options: {
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          x: {
            offset: true,
            ticks: {
              display: !this.deviceDetectorService.isMobile(),
              color: '#002626',
            },
            title: {
              display: false,
              text: this.translateService.instant('dashboard-page.sensor-viewer-panel.date'),
              color: '#002626',
            },
          },
          y: {
            ticks: {
              color: '#002626',
            },
            title: {
              display: false,
              text: this.translateService.instant('dashboard-page.sensor-viewer-panel.wind'),
              color: '#002626',
            },
            grid: {
              drawBorder: false,
              color: function (context) {
                return 'black';
              },
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: this.translateService.instant('dashboard-page.sensor-viewer-panel.wind'),
            color: '#002626',
            font: {
              size: 14,
            },
            padding: {
              bottom: 5,
            },
          },
          legend: {
            display: !this.deviceDetectorService.isMobile(),
            labels: {
              color: '#002626',
            },
          },
        },
        layout: {
          padding: 10,
        },
      },
    });
  }

  changeChartResolution(sensorDataResolutionEnum: SensorDataResolutionEnum, chartNameEnum: ChartNameEnum): void {
    const intervalStart = new Date(this.formControls.startDate.value);
    const intervalEnd = new Date(this.formControls.endDate.value);
    intervalStart.setHours(0, 0, 0, 0);
    intervalEnd.setHours(23, 59, 0, 0);

    switch (chartNameEnum.valueOf()) {
      case ChartNameEnum.temperatureCanvas.valueOf():
        const temperatureChartResolution = this.chartSelectedResolutionMap.find(x => x.chartName === chartNameEnum);
        temperatureChartResolution.selectedResolution = sensorDataResolutionEnum;

        this.sensorDataService
          .getTemperature(
            this.selectedDeviceLocation.id,
            intervalStart,
            intervalEnd,
            temperatureChartResolution.selectedResolution,
          )
          .subscribe(temperature => {
            this.temperatureData = temperature;
            this.clearTemperatureChart();
            this.updateTemperatureChart(temperatureChartResolution.selectedResolution);
          });
        break;

      case ChartNameEnum.waterAmountCanvas.valueOf():
        const waterAmountChartResolution = this.chartSelectedResolutionMap.find(x => x.chartName === chartNameEnum);
        waterAmountChartResolution.selectedResolution = sensorDataResolutionEnum;

        this.sensorDataService
          .getWaterAmount(
            this.selectedDeviceLocation.id,
            intervalStart,
            intervalEnd,
            waterAmountChartResolution.selectedResolution,
          )
          .subscribe(waterAmount => {
            this.waterAmountData = waterAmount;
            this.clearWaterAmountChart();
            this.updateWaterAmountChart(waterAmountChartResolution.selectedResolution);
          });
        break;

      case ChartNameEnum.cumulativeWaterAmountCanvas.valueOf():
        const cumulativeWaterAmountChartResolution = this.chartSelectedResolutionMap.find(
          x => x.chartName === chartNameEnum,
        );
        cumulativeWaterAmountChartResolution.selectedResolution = sensorDataResolutionEnum;

        this.sensorDataService
          .getCumulativeWaterAmount(
            this.selectedDeviceLocation.id,
            intervalStart,
            intervalEnd,
            cumulativeWaterAmountChartResolution.selectedResolution,
          )
          .subscribe(gatewayCumulativeWaterAmount => {
            this.cumulativeWaterAmountData = gatewayCumulativeWaterAmount;
            this.clearCumulativeWaterAmountChart();
            this.updateCumulativeWaterAmountChart(cumulativeWaterAmountChartResolution.selectedResolution);
          });
        break;

      case ChartNameEnum.humidityCanvas.valueOf():
        const humidityChartResolution = this.chartSelectedResolutionMap.find(x => x.chartName === chartNameEnum);
        humidityChartResolution.selectedResolution = sensorDataResolutionEnum;

        this.sensorDataService
          .getHumidity(
            this.selectedDeviceLocation.id,
            intervalStart,
            intervalEnd,
            humidityChartResolution.selectedResolution,
          )
          .subscribe(humidity => {
            this.humidityData = humidity;
            this.clearHumidityChart();
            this.updateHumidityChart(humidityChartResolution.selectedResolution);
          });
        break;

      case ChartNameEnum.ambientLightCanvas.valueOf():
        const ambientLightChartResolution = this.chartSelectedResolutionMap.find(x => x.chartName === chartNameEnum);
        ambientLightChartResolution.selectedResolution = sensorDataResolutionEnum;

        this.sensorDataService
          .getAmbientLight(
            this.selectedDeviceLocation.id,
            intervalStart,
            intervalEnd,
            ambientLightChartResolution.selectedResolution,
          )
          .subscribe(ambientLight => {
            this.ambientLightData = ambientLight;
            this.clearAmbientLightChart();
            this.updateAmbientLightChart(ambientLightChartResolution.selectedResolution);
          });
        break;

      case ChartNameEnum.leafMoistureCanvas.valueOf():
        const leafMoistureChartResolution = this.chartSelectedResolutionMap.find(x => x.chartName === chartNameEnum);
        leafMoistureChartResolution.selectedResolution = sensorDataResolutionEnum;

        this.sensorDataService
          .getLeafMoisture(
            this.selectedDeviceLocation.id,
            intervalStart,
            intervalEnd,
            leafMoistureChartResolution.selectedResolution,
          )
          .subscribe(leafMoisture => {
            this.leafMoistureData = leafMoisture;
            this.clearLeafMoistureChart();
            this.updateLeafMoistureChart(leafMoistureChartResolution.selectedResolution);
          });
        break;

      case ChartNameEnum.windSpeedCanvas.valueOf():
        const windSpeedChartResolution = this.chartSelectedResolutionMap.find(x => x.chartName === chartNameEnum);
        windSpeedChartResolution.selectedResolution = sensorDataResolutionEnum;

        this.sensorDataService
          .getWindSpeed(
            this.selectedDeviceLocation.id,
            intervalStart,
            intervalEnd,
            windSpeedChartResolution.selectedResolution,
          )
          .subscribe(windSpeed => {
            this.windSpeedData = windSpeed;
            this.clearWindSpeedChart();
            this.updateWindSpeedChart(windSpeedChartResolution.selectedResolution);
          });
        break;

      default:
        break;
    }
  }

  getChartResolution(chartNameEnum: ChartNameEnum): ChartResolutionMap {
    return this.chartResolutionMap.find(x => x.chartName === chartNameEnum);
  }

  navigateToDfarmShop(): void {
    window.open('https://dmarketplace.eu', '_blank');
  }

  private getData(): void {
    if (
      this.dateRangeSelectorForm.valid &&
      this.selectedDeviceLocation !== undefined &&
      this.selectedDeviceLocation.coordinate !== undefined
    ) {
      const intervalStart = new Date(this.formControls.startDate.value);
      const intervalEnd = new Date(this.formControls.endDate.value);
      intervalStart.setHours(0, 0, 0, 0);
      intervalEnd.setHours(23, 59, 0, 0);

      const temperatureChartResolution = this.chartSelectedResolutionMap.find(
        x => x.chartName === ChartNameEnum.temperatureCanvas,
      ).selectedResolution;
      const waterAmountChartResolution = this.chartSelectedResolutionMap.find(
        x => x.chartName === ChartNameEnum.waterAmountCanvas,
      ).selectedResolution;
      const cumulativeWaterAmountChartResolution = this.chartSelectedResolutionMap.find(
        x => x.chartName === ChartNameEnum.cumulativeWaterAmountCanvas,
      ).selectedResolution;
      const humidityChartResolution = this.chartSelectedResolutionMap.find(
        x => x.chartName === ChartNameEnum.humidityCanvas,
      ).selectedResolution;
      const ambientLightChartResolution = this.chartSelectedResolutionMap.find(
        x => x.chartName === ChartNameEnum.ambientLightCanvas,
      ).selectedResolution;
      const leafMoistureChartResolution = this.chartSelectedResolutionMap.find(
        x => x.chartName === ChartNameEnum.leafMoistureCanvas,
      ).selectedResolution;
      const windSpeedChartResolution = this.chartSelectedResolutionMap.find(
        x => x.chartName === ChartNameEnum.windSpeedCanvas,
      ).selectedResolution;

      forkJoin([
        this.sensorDataService.getTemperature(
          this.selectedDeviceLocation.id,
          intervalStart,
          intervalEnd,
          temperatureChartResolution,
        ),
        this.sensorDataService.getWaterAmount(
          this.selectedDeviceLocation.id,
          intervalStart,
          intervalEnd,
          waterAmountChartResolution,
        ),
        this.sensorDataService.getCumulativeWaterAmount(
          this.selectedDeviceLocation.id,
          intervalStart,
          intervalEnd,
          cumulativeWaterAmountChartResolution,
        ),
        this.sensorDataService.getAmbientLight(
          this.selectedDeviceLocation.id,
          intervalStart,
          intervalEnd,
          ambientLightChartResolution,
        ),
        this.sensorDataService.getHumidity(
          this.selectedDeviceLocation.id,
          intervalStart,
          intervalEnd,
          humidityChartResolution,
        ),
        this.sensorDataService.getLeafMoisture(
          this.selectedDeviceLocation.id,
          intervalStart,
          intervalEnd,
          leafMoistureChartResolution,
        ),
        this.sensorDataService.getWindSpeed(
          this.selectedDeviceLocation.id,
          intervalStart,
          intervalEnd,
          windSpeedChartResolution,
        ),
      ]).subscribe(
        ([temperature, waterAmount, cumulativeWaterAmount, ambientLight, humidity, leafMoisture, windSpeed]) => {
          this.temperatureData = temperature;
          this.waterAmountData = waterAmount;
          this.cumulativeWaterAmountData = cumulativeWaterAmount;
          this.ambientLightData = ambientLight;
          this.humidityData = humidity;
          this.leafMoistureData = leafMoisture;
          this.windSpeedData = windSpeed;

          this.clearAllCharts();
          this.updateTemperatureChart(temperatureChartResolution);
          this.updateWaterAmountChart(waterAmountChartResolution);
          this.updateCumulativeWaterAmountChart(cumulativeWaterAmountChartResolution);
          this.updateAmbientLightChart(ambientLightChartResolution);
          this.updateHumidityChart(humidityChartResolution);
          this.updateLeafMoistureChart(leafMoistureChartResolution);
          this.updateWindSpeedChart(windSpeedChartResolution);
        },
      );
    }
  }

  private clearTemperatureChart(): void {
    if (this.temperatureChart === undefined) {
      return;
    }

    this.temperatureChart.data.labels = [];
    this.temperatureChart.data.datasets = [];

    this.temperatureChart.update();
  }

  private updateTemperatureChart(sensorDataResolutionEnum: SensorDataResolutionEnum) {
    const newLabels = [];
    const newDatasets = [];

    if (sensorDataResolutionEnum === SensorDataResolutionEnum.hourly) {
      this.temperatureData.hourly.time.forEach(time => {
        newLabels.push(time.toLocaleString());
      });

      const averageDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.average'),
        data: [],
        borderColor: 'rgba(255, 128, 0, 0.8)',
        backgroundColor: 'rgba(255, 128, 0, 0.6)',
      };
      this.temperatureData.hourly.average.forEach(average => {
        averageDataset.data.push(average);
      });
      newDatasets.push(averageDataset);
    } else if (sensorDataResolutionEnum === SensorDataResolutionEnum.daily) {
      this.temperatureData.daily.time.forEach(time => {
        newLabels.push(time.toLocaleDateString());
      });

      const minimumDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.minimum'),
        data: [],
        borderColor: 'rgba(0, 128, 255, 0.8)',
        backgroundColor: 'rgba(0, 128, 255, 0.6)',
      };
      this.temperatureData.daily.minimum.forEach(minimum => {
        minimumDataset.data.push(minimum);
      });
      newDatasets.push(minimumDataset);

      const averageDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.average'),
        data: [],
        borderColor: 'rgba(255, 128, 0, 0.8)',
        backgroundColor: 'rgba(255, 128, 0, 0.6)',
      };
      this.temperatureData.daily.average.forEach(average => {
        averageDataset.data.push(average);
      });
      newDatasets.push(averageDataset);

      const maximumDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.maximum'),
        data: [],
        borderColor: 'rgba(255, 0, 0, 0.8)',
        backgroundColor: 'rgba(255, 0, 0, 0.6)',
      };
      this.temperatureData.daily.maximum.forEach(maximum => {
        maximumDataset.data.push(maximum);
      });
      newDatasets.push(maximumDataset);
    }

    newLabels.forEach(label => {
      this.temperatureChart.data.labels.push(label);
    });
    newDatasets.forEach(dataset => {
      this.temperatureChart.data.datasets.push(dataset);
    });

    this.temperatureChart.update();
  }

  private clearWaterAmountChart(): void {
    if (this.waterAmountChart === undefined) {
      return;
    }

    this.waterAmountChart.data.labels = [];
    this.waterAmountChart.data.datasets = [];

    this.waterAmountChart.update();
  }

  private updateWaterAmountChart(sensorDataResolutionEnum: SensorDataResolutionEnum) {
    const newLabels = [];
    const newDatasets = [];

    if (sensorDataResolutionEnum === SensorDataResolutionEnum.raw) {
      this.waterAmountData.raw.time.forEach(time => {
        newLabels.push(time.toLocaleString());
      });

      const valuesDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.value'),
        data: [],
        borderColor: 'rgba(0, 128, 255, 0.8)',
        backgroundColor: 'rgba(0, 128, 255, 0.6)',
      };
      this.waterAmountData.raw.values.forEach(value => {
        valuesDataset.data.push(value);
      });
      newDatasets.push(valuesDataset);
    } else if (sensorDataResolutionEnum === SensorDataResolutionEnum.hourly) {
      this.waterAmountData.hourly.time.forEach(time => {
        newLabels.push(time.toLocaleString());
      });

      const sumDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.sum'),
        data: [],
        borderColor: 'rgba(0, 128, 255, 0.8)',
        backgroundColor: 'rgba(0, 128, 255, 0.6)',
      };
      this.waterAmountData.hourly.sum.forEach(sum => {
        sumDataset.data.push(sum);
      });
      newDatasets.push(sumDataset);
    } else if (sensorDataResolutionEnum === SensorDataResolutionEnum.daily) {
      this.waterAmountData.daily.time.forEach(time => {
        newLabels.push(time.toLocaleDateString());
      });

      const sumDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.sum'),
        data: [],
        borderColor: 'rgba(0, 128, 255, 0.8)',
        backgroundColor: 'rgba(0, 128, 255, 0.6)',
      };
      this.waterAmountData.daily.sum.forEach(sum => {
        sumDataset.data.push(sum);
      });
      newDatasets.push(sumDataset);
    }

    newLabels.forEach(label => {
      this.waterAmountChart.data.labels.push(label);
    });
    newDatasets.forEach(dataset => {
      this.waterAmountChart.data.datasets.push(dataset);
    });

    this.waterAmountChart.update();
  }

  private clearCumulativeWaterAmountChart(): void {
    if (this.cumulativeWaterAmountChart === undefined) {
      return;
    }

    this.cumulativeWaterAmountChart.data.labels = [];
    this.cumulativeWaterAmountChart.data.datasets = [];

    this.cumulativeWaterAmountChart.update();
  }

  private updateCumulativeWaterAmountChart(sensorDataResolutionEnum: SensorDataResolutionEnum) {
    const newLabels = [];
    const newDatasets = [];

    if (sensorDataResolutionEnum === SensorDataResolutionEnum.hourly) {
      this.cumulativeWaterAmountData.hourly.time.forEach(time => {
        newLabels.push(time.toLocaleString());
      });

      const sumDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.sum'),
        data: [],
        borderColor: 'rgba(0, 128, 255, 0.8)',
        backgroundColor: 'rgba(0, 128, 255, 0.6)',
        fill: true,
      };
      this.cumulativeWaterAmountData.hourly.sum.forEach(sum => {
        sumDataset.data.push(sum);
      });
      newDatasets.push(sumDataset);
    } else if (sensorDataResolutionEnum === SensorDataResolutionEnum.daily) {
      this.cumulativeWaterAmountData.daily.time.forEach(time => {
        newLabels.push(time.toLocaleDateString());
      });

      const sumDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.sum'),
        data: [],
        borderColor: 'rgba(0, 128, 255, 0.8)',
        backgroundColor: 'rgba(0, 128, 255, 0.6)',
        fill: true,
      };
      this.cumulativeWaterAmountData.daily.sum.forEach(sum => {
        sumDataset.data.push(sum);
      });
      newDatasets.push(sumDataset);
    }

    newLabels.forEach(label => {
      this.cumulativeWaterAmountChart.data.labels.push(label);
    });
    newDatasets.forEach(dataset => {
      this.cumulativeWaterAmountChart.data.datasets.push(dataset);
    });

    this.cumulativeWaterAmountChart.update();
  }

  private clearHumidityChart(): void {
    if (this.humidityChart === undefined) {
      return;
    }

    this.humidityChart.data.labels = [];
    this.humidityChart.data.datasets = [];

    this.humidityChart.update();
  }

  private updateHumidityChart(sensorDataResolutionEnum: SensorDataResolutionEnum) {
    const newLabels = [];
    const newDatasets = [];

    if (sensorDataResolutionEnum === SensorDataResolutionEnum.hourly) {
      this.humidityData.hourly.time.forEach(time => {
        newLabels.push(time.toLocaleString());
      });

      const averageDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.average'),
        data: [],
        borderColor: 'rgba(255, 128, 0, 0.8)',
        backgroundColor: 'rgba(255, 128, 0, 0.6)',
      };
      this.humidityData.hourly.average.forEach(average => {
        averageDataset.data.push(average);
      });
      newDatasets.push(averageDataset);
    } else if (sensorDataResolutionEnum === SensorDataResolutionEnum.daily) {
      this.humidityData.daily.time.forEach(time => {
        newLabels.push(time.toLocaleDateString());
      });

      const averageDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.average'),
        data: [],
        borderColor: 'rgba(255, 128, 0, 0.8)',
        backgroundColor: 'rgba(255, 128, 0, 0.6)',
      };
      this.humidityData.daily.average.forEach(average => {
        averageDataset.data.push(average);
      });
      newDatasets.push(averageDataset);
    }

    newLabels.forEach(label => {
      this.humidityChart.data.labels.push(label);
    });
    newDatasets.forEach(dataset => {
      this.humidityChart.data.datasets.push(dataset);
    });

    this.humidityChart.update();
  }

  private clearAmbientLightChart(): void {
    if (this.ambientLightChart === undefined) {
      return;
    }

    this.ambientLightChart.data.labels = [];
    this.ambientLightChart.data.datasets = [];

    this.ambientLightChart.update();
  }

  private updateAmbientLightChart(sensorDataResolutionEnum: SensorDataResolutionEnum) {
    const newLabels = [];
    const newDatasets = [];

    if (sensorDataResolutionEnum === SensorDataResolutionEnum.hourly) {
      this.ambientLightData.hourly.time.forEach(time => {
        newLabels.push(time.toLocaleString());
      });

      const averageDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.average'),
        data: [],
        borderColor: 'rgba(255, 128, 0, 0.8)',
        backgroundColor: 'rgba(255, 128, 0, 0.6)',
      };
      this.ambientLightData.hourly.average.forEach(average => {
        averageDataset.data.push(average);
      });
      newDatasets.push(averageDataset);
    }

    newLabels.forEach(label => {
      this.ambientLightChart.data.labels.push(label);
    });
    newDatasets.forEach(dataset => {
      this.ambientLightChart.data.datasets.push(dataset);
    });

    this.ambientLightChart.update();
  }

  private clearLeafMoistureChart(): void {
    if (this.leafMoistureChart === undefined) {
      return;
    }

    this.leafMoistureChart.data.labels = [];
    this.leafMoistureChart.data.datasets = [];

    this.leafMoistureChart.update();
  }

  private updateLeafMoistureChart(sensorDataResolutionEnum: SensorDataResolutionEnum) {
    const newLabels = [];
    const newDatasets = [];

    if (sensorDataResolutionEnum === SensorDataResolutionEnum.hourly) {
      this.leafMoistureData.hourly.time.forEach(time => {
        newLabels.push(time.toLocaleString());
      });

      const averageDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.average'),
        data: [],
        borderColor: 'rgba(255, 128, 0, 0.8)',
        backgroundColor: 'rgba(255, 128, 0, 0.6)',
      };
      this.leafMoistureData.hourly.average.forEach(average => {
        averageDataset.data.push(average);
      });
      newDatasets.push(averageDataset);
    } else if (sensorDataResolutionEnum === SensorDataResolutionEnum.daily) {
      this.leafMoistureData.daily.time.forEach(time => {
        newLabels.push(time.toLocaleDateString());
      });

      const averageDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.average'),
        data: [],
        borderColor: 'rgba(255, 128, 0, 0.8)',
        backgroundColor: 'rgba(255, 128, 0, 0.6)',
      };
      this.leafMoistureData.daily.average.forEach(average => {
        averageDataset.data.push(average);
      });
      newDatasets.push(averageDataset);
    }

    newLabels.forEach(label => {
      this.leafMoistureChart.data.labels.push(label);
    });
    newDatasets.forEach(dataset => {
      this.leafMoistureChart.data.datasets.push(dataset);
    });

    this.leafMoistureChart.update();
  }

  private clearWindSpeedChart(): void {
    if (this.windSpeedChart === undefined) {
      return;
    }

    this.windSpeedChart.data.labels = [];
    this.windSpeedChart.data.datasets = [];

    this.windSpeedChart.update();
  }

  private updateWindSpeedChart(sensorDataResolutionEnum: SensorDataResolutionEnum) {
    const newLabels = [];
    const newDatasets = [];

    if (sensorDataResolutionEnum === SensorDataResolutionEnum.hourly) {
      this.windSpeedData.hourly.time.forEach(time => {
        newLabels.push(time.toLocaleString());
      });

      const averageDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.average'),
        data: [],
        borderColor: 'rgba(255, 128, 0, 0.8)',
        backgroundColor: 'rgba(255, 128, 0, 0.6)',
      };
      this.windSpeedData.hourly.average.forEach(average => {
        averageDataset.data.push(average);
      });
      newDatasets.push(averageDataset);
    } else if (sensorDataResolutionEnum === SensorDataResolutionEnum.daily) {
      this.windSpeedData.daily.time.forEach(time => {
        newLabels.push(time.toLocaleDateString());
      });

      const averageDataset = {
        label: this.translateService.instant('dashboard-page.sensor-viewer-panel.average'),
        data: [],
        borderColor: 'rgba(255, 128, 0, 0.8)',
        backgroundColor: 'rgba(255, 128, 0, 0.6)',
      };
      this.windSpeedData.daily.average.forEach(average => {
        averageDataset.data.push(average);
      });
      newDatasets.push(averageDataset);
    }

    newLabels.forEach(label => {
      this.windSpeedChart.data.labels.push(label);
    });
    newDatasets.forEach(dataset => {
      this.windSpeedChart.data.datasets.push(dataset);
    });

    this.windSpeedChart.update();
  }

  private clearAllCharts(): void {
    this.clearTemperatureChart();
    this.clearWaterAmountChart();
    this.clearCumulativeWaterAmountChart();
    this.clearHumidityChart();
    this.clearAmbientLightChart();
    this.clearLeafMoistureChart();
    this.clearWindSpeedChart();
  }
}
