<dfarm-panel
  *ngIf="!isLoading"
  class="parent-panel"
  [isClosable]="true"
  [isLeftSide]="false"
  [isMultiViewPanel]="true"
  [isVisible]="panelIsOpen">
  <div class="child-panel">
    <div class="title text-xl">Öntözők listázása</div>

    <form class="w-full text-sm mb-2 flex justify-evenly items-center text-white" [formGroup]="dateRangeSelectorForm">
      <mat-form-field appearance="outline" class="w-full">
        <mat-date-range-input class="!cursor-pointer" [max]="maxDate" [rangePicker]="picker" (click)="picker.open()">
          <input class="!cursor-pointer" disabled formControlName="startDate" matStartDate placeholder="Start date" />
          <input class="!cursor-pointer" disabled formControlName="endDate" matEndDate placeholder="End date" />
        </mat-date-range-input>
        <mat-hint class="text-dark-green">YYYY. MM. DD. – YYYY. MM. DD.</mat-hint>
        <mat-datepicker-toggle [for]="picker" matIconSuffix />
        <mat-date-range-picker #picker />
      </mat-form-field>
    </form>

    <div class="soil-laboratory-table overflow-auto p-4 pt-0">
      <table class="w-full bg-dark-gray" [dataSource]="dataSource" mat-table>
        <!-- Field Name Column -->
        <ng-container matColumnDef="fieldName">
          <th *matHeaderCellDef class="text-white text-lg !px-2" mat-header-cell>Tábla</th>
          <td *matCellDef="let element" class="text-white !px-2 rounded-l-lg" mat-cell>
            {{ element.fieldName }}
          </td>
          <td *matFooterCellDef mat-footer-cell>Összes öntözött mennyiség</td>
        </ng-container>

        <!-- Irrigated Amount Column -->
        <ng-container matColumnDef="irrigatedAmount">
          <th *matHeaderCellDef class="text-white text-lg !px-2" mat-header-cell>Öntözött mennyiség</th>
          <td *matCellDef="let element" class="text-white !px-2" mat-cell>
            {{ element.irrigatedAmount }} m<sup>3</sup>
          </td>
          <td *matFooterCellDef mat-footer-cell>{{ totalIrrigatedAmount() }} m<sup>3</sup></td>
        </ng-container>

        <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
        <tr
          *matRowDef="let row; columns: displayedColumns"
          class="hover:bg-dark-green hover:cursor-pointer transition-all duration-300"
          mat-row></tr>
        <tr *matFooterRowDef="displayedColumns" mat-footer-row></tr>
      </table>
    </div>
  </div>
</dfarm-panel>
