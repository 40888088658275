<form class="dateRangeSelectorForm" [formGroup]="dateRangeSelectorForm" (ngSubmit)="onSubmit()">
  <mat-form-field appearance="outline" class="w-full">
    <mat-date-range-input class="!cursor-pointer" [max]="maxDate" [rangePicker]="picker" (click)="picker.open()">
      <input class="!cursor-pointer" disabled formControlName="startDate" matStartDate placeholder="Start date" />
      <input class="!cursor-pointer" disabled formControlName="endDate" matEndDate placeholder="End date" />
    </mat-date-range-input>
    <mat-hint class="text-dark-green">YYYY. MM. DD. – YYYY. MM. DD.</mat-hint>
    <mat-datepicker-toggle [for]="picker" matIconSuffix />
    <mat-date-range-picker #picker />
  </mat-form-field>
  <!--  <button type="submit" [disabled]="!dateRangeSelectorForm.valid" class="submit-button">Lekérdez</button>-->
</form>

<div class="satelliteImageContainer">
  <div>
    Műholdas felvételek:
    <button
      class="satelliteImageSelectorButton"
      [ngClass]="{
        satelliteImageSelectorBlurredButton: selectedImageType !== TRUE_COLOR,
      }"
      (click)="selectImageType(TRUE_COLOR)">
      True Color
    </button>
    <button
      class="satelliteImageSelectorButton"
      [ngClass]="{
        satelliteImageSelectorBlurredButton: selectedImageType !== NDVI,
      }"
      (click)="selectImageType(NDVI)">
      NDVI
    </button>
  </div>
  <div class="satelliteImageSelector">
    <div class="sliderWrapper">
      <label>{{ dateLabel }}</label>
      <!-- <mat-slider
				[min]="sliderMin"
				[max]="sliderMax"
				[value]="sliderValue"
			></mat-slider> -->
      <div>
        <button class="satelliteImageSelectorButton" (click)="previousImage()">Előző</button>
        <button class="satelliteImageSelectorButton" (click)="playClicked()">
          {{ playButtonOn ? STOP : PLAY }}
        </button>
        <button class="satelliteImageSelectorButton" (click)="nextImage()">Következő</button>
      </div>
    </div>
  </div>
</div>

<div class="chart-container">
  <canvas id="airTemperatureCanvas" #airTemperatureCanvas width="200" height="100"></canvas>
</div>
<div class="chart-container">
  <canvas
    id="cumulativeAverageAirTemperatureCanvas"
    #cumulativeAverageAirTemperatureCanvas
    width="200"
    height="100"></canvas>
</div>
<div class="chart-container">
  <canvas id="soilTemperatureT0Canvas" #soilTemperatureT0Canvas width="200" height="100"></canvas>
</div>
<div class="chart-container">
  <canvas
    id="cumulativeAverageSoilTemperatureT0Canvas"
    #cumulativeAverageSoilTemperatureT0Canvas
    width="200"
    height="100"></canvas>
</div>
<div class="chart-container">
  <canvas id="soilTemperatureT10Canvas" #soilTemperatureT10Canvas width="200" height="100"></canvas>
</div>
<div class="chart-container">
  <canvas
    id="cumulativeAverageSoilTemperatureT10Canvas"
    #cumulativeAverageSoilTemperatureT10Canvas
    width="200"
    height="100"></canvas>
</div>
<div class="chart-container">
  <canvas id="precipitationCanvas" #precipitationCanvas width="200" height="100"></canvas>
</div>
<div class="chart-container">
  <canvas id="cumulativePrecipitationCanvas" #cumulativePrecipitationCanvas width="200" height="100"></canvas>
</div>
<div class="chart-container">
  <canvas id="humidityCanvas" #humidityCanvas width="200" height="100"></canvas>
</div>
